import Http from "./Http";

const http = new Http();

export default class bodPersonnel {
  // GET: /bod-personnel/
  static getPersonnel() {
    return http.get<any>({
      endpoint: `/bod-personnel/`,
    });
  }

  // POST: /bod-personnel/
  static postPersonnel(args: any) {
    return http.post<any>({
      endpoint: `/bod-personnel/`,
      payload: args,
    });
  }

  // GET: /bod-personnel/{uuid}/
  static getPersonnelById(uuid: string) {
    return http.get<any>({
      endpoint: `/bod-personnel/${uuid}/`,
    });
  }

  // DELETE: /bod-personnel/{uuid}/
  static deletePersonnel(uuid: string) {
    return http.delete<any>({
      endpoint: `/bod-personnel/${uuid}/`,
    });
  }
}
