import Http from "./Http";

const http = new Http();

export default class DurationTuitionService {
  // GET: /api/duration-tuition/
  static getDurationTuitions() {
    return http.get<any>({
      endpoint: `/duration-tuition/`,
    });
  }

  // POST: /api/duration-tuition/
  static postDurationTuition(args: any) {
    return http.post<any>({
      endpoint: `/duration-tuition/`,
      payload: args,
    });
  }

  // GET: /api/duration-tuition/{uuid}/
  static getDurationTuitionById(uuid: string) {
    return http.get<any>({
      endpoint: `/duration-tuition/${uuid}/`,
    });
  }

  // DELETE: /api/duration-tuition/{uuid}/
  static deleteDurationTuition(uuid: string) {
    return http.delete<any>({
      endpoint: `/duration-tuition/${uuid}/`,
    });
  }
}
