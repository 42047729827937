import Http from "./Http";

const http = new Http();

export default class ProjectGrit {
  static getProjects() {
    return http.get<any>({
      endpoint: `/project-grit/`,
    });
  }

  static postProject(args: any) {
    return http.post<any>({
      endpoint: `/project-grit/`,
      payload: args,
    });
  }

  static getProject(uuid: string) {
    return http.get<any>({
      endpoint: `/project-grit/${uuid}/`,
    });
  }

  static deleteProject(uuid: string) {
    return http.delete<any>({
      endpoint: `/project-grit/${uuid}/`,
    });
  }
}
