import Http from "./Http";

const http = new Http();

export default class PpssService {
  static getPPSS() {
    return http.get<any>({
      endpoint: `/heart/ppss/`,
    });
  }
  static postPPSS(args: any) {
    return http.post<any>({
      endpoint: `/heart/ppss/`,
      payload: args,
    });
  }
  static patchPPSS(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/ppss/${id}/`,
      payload: args,
    });
  }
}
