import { ReactNode } from "react";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./style.scss";

interface IProps {
  children: ReactNode;
  title: string;
  show: boolean;
  setShow: (show: boolean) => void;
  maxWidth?: "sm" | "md" | "lg" | "xl";
}

const styles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 2,
    },
    closeButton: {
      backgroundColor: "gray !important",
      color: "white !important",
      padding: "10px !important",
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = styles();

  return (
    <MuiDialogTitle {...other}>
      <div className={classes.dialogHeader}>
        <Typography variant="h4">{children}</Typography>
        <div>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              className={classes.closeButton}
            >
              <CloseIcon fontSize={"small"} />
            </IconButton>
          ) : null}
        </div>
      </div>
    </MuiDialogTitle>
  );
};

function FormDialog(props: IProps) {
  const { show, setShow, title, children, maxWidth } = props;

  const handleClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Dialog
        maxWidth={maxWidth ? maxWidth : "md"}
        open={show}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        className="form-dialog"
      >
        <DialogTitle onClose={handleClose} id="dialog-title">
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

export default FormDialog;
