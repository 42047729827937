import Http from "./Http";

const http = new Http();

export class FileService {
  static postFile(args: any) {
    return http.post<any>({
      endpoint: `/commons/file/`,
      payload: args,
    });
  }
  static requestVideoChunk(args: any) {
    return http.post<any>({
      endpoint: `/video-processor/process/`,
      payload: args,
    });
  }
}
