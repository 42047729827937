import React from "react";
import EventService from "../../../common/services/EventService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm"; // Import the reusable form
import { Field } from "../FormComponent/types";

interface EventFormProps {
  onSuccess: () => void; // New prop
}

const EventForm: React.FC<EventFormProps> = ({ onSuccess }) => {
  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      name: data.name,
      date: data.date,
      time: data.time,
      location: data.location,
      description: data.description,
      type: data.type,
    };

    EventService.postEvent(payload)
      .then((response: any) => {
        toast.success("Event saved successfully!");
        onSuccess(); // Call the callback on success
      })
      .catch((error: any) => {
        toast.error("Failed to save event!");
      });
  };

  const formFields: Field[] = [
    { id: "name", type: "text" as const, label: "Event Name", required: true },
    { id: "date", type: "date" as const, label: "Event Date", required: true },
    { id: "time", type: "time" as const, label: "Event Time", required: true },
    { id: "location", type: "text" as const, label: "Event Location", required: true },
    { id: "description", type: "textarea" as const, label: "Event Description", required: true },
    { id: "type", type: "text" as const, label: "Event Type", required: true },
  ];

  return (
    <>
      <div>
        <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default EventForm;
