import React, { useState, useEffect } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import EventForm from "./EventForm"; // Import the EventForm component
import EventService from "../../../common/services/EventService"; // Import EventService

type EventRow = {
  id: string;
  name: string;
  date: string;
  time: string;
  location: string;
  description: string;
  type: string;
};

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  { id: "date", label: "Date", minWidth: 100, align: "left" },
  { id: "time", label: "Time", minWidth: 100, align: "left" },
  { id: "location", label: "Location", minWidth: 150, align: "left" },
  { id: "description", label: "Description", minWidth: 200, align: "left" },
  { id: "type", label: "Type", minWidth: 100, align: "left" },
];

const EventView = () => {
  const [rows, setRows] = useState<EventRow[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [editData, setEditData] = useState<EventRow | null>(null);
  const [rowToDelete, setRowToDelete] = useState<EventRow | null>(null);

  // Fetch data from the API on component load
  useEffect(() => {
    fetchEventData();
  }, []);

  // Function to fetch event data from the API
  const fetchEventData = async () => {
    try {
      const response = await EventService.getEvents();
      console.log("Event data fetched:", response);
      setRows(response);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const handleEdit = (row: EventRow) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: EventRow) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
  };

  const handleSave = () => {
    if (editData) {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === editData.id ? editData : row))
      );
      setOpenEdit(false);
    }
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
      setOpenDelete(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (editData) {
      setEditData({ ...editData, [name]: value });
    }
  };

  // Callback function to refresh data
  const handleEventAdded = () => {
    fetchEventData(); // Refresh data after event is added
    setOpenEventModal(false); // Close modal
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Events</div>
          <div>
            <Button 
              onClick={() => setOpenEventModal(true)}
              className={style.openModel}
            >
              Add Event
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Event</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={editData?.name || ""}
              onChange={handleChange}
            />
            <TextField
              label="Date"
              name="date"
              type="date"
              fullWidth
              margin="normal"
              value={editData?.date || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Time"
              name="time"
              type="time"
              fullWidth
              margin="normal"
              value={editData?.time || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Location"
              name="location"
              fullWidth
              margin="normal"
              value={editData?.location || ""}
              onChange={handleChange}
            />
            <TextField
              label="Description"
              name="description"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={editData?.description || ""}
              onChange={handleChange}
            />
            <TextField
              label="Type"
              name="type"
              fullWidth
              margin="normal"
              value={editData?.type || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this event?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="secondary" className={style.deleteBtn}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEventModal} onClose={handleCloseEventModal}>
          <DialogTitle>
            Add Event
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseEventModal}
              aria-label="close"
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <EventForm onSuccess={handleEventAdded} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default EventView;
