import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import Auth from "../../../common/services/UserService";
import "./styles.css";

const formatDateTime = (dateTime: string | number | Date) => {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const UserTable = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    Auth.getPartners("/api/v1/account/partners/")
      .then((response) => {
        setPartners(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Try to parse the response as JSON
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <AdminHeader title="Partners" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Partner List</h1>
        <Link to={`/admin/addpartner`}>
          <div className="addpar">
            <Button variant="contained" color="primary">
              <span className="symbol">+</span> Partner
            </Button>
          </div>
        </Link>
      </div>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell className="t_bold">Partner Name</TableCell>
            <TableCell className="t_bold">Email</TableCell>
            <TableCell className="t_bold">Phone Number</TableCell>
            <TableCell className="t_bold">Users</TableCell>
            <TableCell className="t_bold">NDA Signed</TableCell>
            <TableCell className="t_bold">Created At</TableCell>
            <TableCell className="t_bold">Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partners.map((partner: any) => (
            <TableRow key={partner.id}>
              <TableCell>{partner.name}</TableCell>
              <TableCell>{partner.email}</TableCell>
              <TableCell>{partner.phone_number}</TableCell>
              <TableCell>{partner.users}</TableCell>
              <TableCell>{partner.NDASigned ? "Yes" : "No"}</TableCell>
              <TableCell>{formatDateTime(partner.created_at)}</TableCell>
              <TableCell>{formatDateTime(partner.updated_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserTable;
