import React, { useEffect, useState } from "react";
import TuitionService from "../../../common/services/TuitionService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm"; // Import the reusable form
import { Field } from "../FormComponent/types";

const TuitionForm = () => {
  const [courseOptions, setCourseOptions] = useState<{ value: string, label: string }[]>([]);
  const [durationOptions, setDurationOptions] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    // Fetch courses and durations from the API or define them here
    const fetchOptions = () => {
      setCourseOptions([
        { value: "4c88e8b1-8fa9-4021-9bad-b22efe4d02eb", label: "Course 1" },
        { value: "another-course-uuid", label: "Course 2" },
      ]);

      setDurationOptions([
        { value: "fb0c8ecd-4023-4650-88aa-93b9d2c9ec77", label: "Duration 1" },
        { value: "another-duration-uuid", label: "Duration 2" },
      ]);
    };

    fetchOptions();
  }, []);

  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      semester: data.semester,
      fee: data.fee,
      course: data.course,
      duration: data.duration,
    };

    TuitionService.postTuition(payload)
      .then((response: any) => {
        toast.success("Tuition saved successfully!");
      })
      .catch((error: any) => {
        toast.error("Failed to save tuition!");
      });
  };

  const formFields: Field[] = [
    {
      id: "semester",
      type: "select" as const,
      label: "Semester",
      required: true,
      options: [
        { value: "FALL SEMESTER", label: "Fall Semester" },
        { value: "SPRING SEMESTER", label: "Spring Semester" },
      ],
    },
    {
      id: "fee",
      type: "text" as const,
      label: "Fee",
      required: true,
    },
    {
      id: "course",
      type: "select" as const,
      label: "Course",
      required: true,
      options: courseOptions, // Populated from the state
    },
    {
      id: "duration",
      type: "select" as const,
      label: "Duration",
      required: true,
      options: durationOptions, // Populated from the state
    },
  ];

  return (
    <div>
      <ReusableForm fields={formFields} onSubmit={handleSubmit} />
    </div>
  );
};

export default TuitionForm;
