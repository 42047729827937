import Http from "./Http";

const http = new Http();

export default class bodDesignation {
  static getDesignations() {
    return http.get<any>({
      endpoint: `/bod-designation/`,
    });
  }

  static postDesignation(args: any) {
    return http.post<any>({
      endpoint: `/bod-designation/`,
      payload: args,
    });
  }

  static putDesignation(args: any, uuid: string) {
    return http.put<any>({
      endpoint: `/bod-designation/${uuid}/`,
      payload: args,
    });
  }
  
  static patchDesignation(args: any, uuid: string) {
    return http.patch<any>({
      endpoint: `/bod-designation/${uuid}/`,
      payload: args,
    });
  }
  static deleteDesignation(uuid: string) {
    return http.delete<any>({
      endpoint: `/bod-designation/${uuid}/`,
    });
  }
}
