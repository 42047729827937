import { useEffect, useState } from "react";
import ppsfService from "../../../common/services/Ppsf";
import { toast } from "react-toastify";
import CustomEditor from "../../../common/components/CustomEditor";
import Preview from "../../../common/components/Dialog/Preview";

const PPSF = () => {
  const [ppsfData, setppsfData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!ppsfData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      ppsfService.patchPPSF(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          toast.error('Failed to save!')
        });
    } else {
      ppsfService.postPPSF(payload)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          toast.error('Failed to save!')
        });
    }
  };

  useEffect(() => {
    ppsfService.getPPSF()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setppsfData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, []);

  return (
    <div style={{ marginTop: "3.5rem" }}>
      <CustomEditor submitHandler={handleSubmit} data={ppsfData} handleOpenPreview={handleOpenPreview} />
      <Preview title="Packsize Project Submission Form" open={openPreview} onClose={handleClosePreview} blocks={ppsfData} />
    </div>
  );
};

export default PPSF;
