import React, { useEffect, useState } from 'react'
import x7cadService from '../../../../common/services/x7cad';
import Preview from '../../../../common/components/Dialog/Preview';
import CustomEditor from '../../../../common/components/CustomEditor';
import { toast } from 'react-toastify';

const X7CM = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!data && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      x7cadService.patchx7cad(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .catch((error: any) => {
          toast.error('Failed to save!')
        });
    } else {
      x7cadService.postx7cad(payload)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .catch((error: any) => {
          toast.error('Failed to save!')
        });
    }
  };

  useEffect(() => {
    x7cadService.getx7cad()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  });
  return (

    <div style={{ marginTop: '3.5rem' }}>
      <CustomEditor submitHandler={handleSubmit} data={data} handleOpenPreview={handleOpenPreview} />
      <Preview title='X7 CAD Models' open={openPreview} onClose={handleClosePreview} blocks={data} />
    </div>

  )
}

export default X7CM