import React, { useEffect, useState } from "react";
import ppoTvService from "../../../common/services/PpoTv";
// import Editor2 from "../../../common/components/Editor2";
import Preview from "../../../common/components/Dialog/Preview";
import CustomEditor from "../../../common/components/CustomEditor";
import { toast } from "react-toastify";
// import RichTextEditor from '../RichTextEditor'

const PPOTV = () => {
  const [ppoTvData, setppoTvData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!ppoTvData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      ppoTvService.patchPPOTV(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!")
          console.log(response);
        })
        .then((error: any) => {
          console.log(error);
        });
    } else {
      ppoTvService.postPPOTV(payload)
        .then((response: any) => {

          console.log(response);
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    ppoTvService.getPPOTV()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setppoTvData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);



  return (
    <div style={{ marginTop: "3.5rem" }}>
       <CustomEditor submitHandler={handleSubmit} data={ppoTvData} handleOpenPreview={handleOpenPreview} />
      <Preview title="Packsize Product Overview & Training Videos" open={openPreview} onClose={handleClosePreview} blocks={ppoTvData} />
    </div>
  );
};

export default PPOTV;
