import Http from "./Http";

const http = new Http();

export default class PBCRService {
  static getPBCR() {
    return http.get<any>({
      endpoint: `/heart/pbcr/`,
    });
  }
  static postPBCR(args: any) {
    return http.post<any>({
      endpoint: `/heart/pbcr/`,
      payload: args,
    });
  }
  static patchPBCR(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/pbcr/${id}/`,
      payload: args,
    });
  }
}
