import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styles from './styles.module.scss';
import './styles.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
interface Iprops {
  pdf: string;
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfParser = (props: Iprops) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { pdf } = props;
  
  const onDocumentLoadSuccess = (numPages: any) => {
    setNumPages(numPages);
  };

  function changePage(offset: any) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  };
  return (
    <div className={styles['pdf-div']}>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          width={400}
          scale={1.9}
          className={["page"]}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        <div className={styles["modalbtn-conatiner"]}>
          {pageNumber !== 1 && (
            <button
              onClick={() => previousPage()}
              className={styles["btn-prev"]}
            ><NavigateBeforeIcon fontSize="large" /></button>
          )}
          {pageNumber !== numPages?._pdfInfo?.numPages && (
            <button onClick={() => nextPage()} className={styles["btn-next"]}><NavigateNextIcon fontSize="large" /></button>
          )}
        </div>
      </Document>
    </div>
  );
};

export default PdfParser;
