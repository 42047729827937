import Http from "./Http";

const http = new Http();

export default class EventService {
  // GET: /event/
  static getEvents() {
    return http.get<any>({
      endpoint: `/event/`,
    });
  }

  // POST: /event/
  static postEvent(args: any) {
    return http.post<any>({
      endpoint: `/event/`,
      payload: args,
    });
  }

  // GET: /event/{uuid}/
  static getEventById(uuid: string) {
    return http.get<any>({
      endpoint: `/event/${uuid}/`,
    });
  }

  // DELETE: /event/{uuid}/
  static deleteEvent(uuid: string) {
    return http.delete<any>({
      endpoint: `/event/${uuid}/`,
    });
  }
}
