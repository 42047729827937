import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import Routers from "./common/Routes";
import { CurrentUserProvider } from "./common/context/CurrentUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <CurrentUserProvider>
        <Routers />
      </CurrentUserProvider>
      <ToastContainer
        position={"top-center"}
        autoClose={2000}
        theme={"colored"}
      />
    </>
  );
}

export default App;
