import React, { useState } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import TutionForm from "./TutionForm";

const columns: Column[] = [
  { id: "semester", label: "Semester", minWidth: 170, align: "left" },
  { id: "course", label: "Course", minWidth: 170, align: "left" },
  { id: "fee", label: "Fee", minWidth: 170, align: "left" },
  { id: "duration", label: "Duration", minWidth: 170, align: "left" },
];

const initialRows = [
  {
    id: 1,
    semester: "FALL SEMESTER",
    course: "4c88e8b1-8fa9-4021-9bad-b22efe4d02eb",
    fee: "1000",
    duration: "fb0c8ecd-4023-4650-88aa-93b9d2c9ec77",
  },
  {
    id: 2,
    semester: "SPRING SEMESTER",
    course: "another-course-uuid",
    fee: "1500",
    duration: "another-duration-uuid",
  },
  {
    id: 3,
    semester: "SPRING SEMESTER",
    course: "another-course-uuid",
    fee: "1500",
    duration: "another-duration-uuid",
  },
  {
    id: 4,
    semester: "SPRING SEMESTER",
    course: "another-course-uuid",
    fee: "1500",
    duration: "another-duration-uuid",
  },
];

const TutionView = () => {
  const [rows, setRows] = useState(initialRows);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false); // State to manage the modal
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === editData.id ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Tution</div>
          <div onClick={() => setOpenModal(true)} className={style.openModel}>
            Add Tution
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            // search={true}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Row</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={editData?.name || ""}
              onChange={handleChange}
            />
            <TextField
              label="ISO Code"
              name="code"
              fullWidth
              margin="normal"
              value={editData?.code || ""}
              onChange={handleChange}
            />
            <TextField
              label="Population"
              name="population"
              fullWidth
              margin="normal"
              value={editData?.population || ""}
              onChange={handleChange}
            />
            <TextField
              label="Size"
              name="size"
              fullWidth
              margin="normal"
              value={editData?.size || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this row?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="secondary"
              className={style.modelButton}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Tution Form
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TutionForm />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default TutionView;
