import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, sideNavList } from "..";
import style from './style.module.scss';

// interface IPreviewImgProps {
//   fillColor?: string
//   strokeColor?: string
// }

// const PreviewImg = ({ strokeColor, fillColor }: IPreviewImgProps) => {
//   return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><g fill={fillColor || 'none'} stroke={strokeColor || 'currentColor'} stroke-linejoin="round" stroke-width="4"><path d="M24 36c11.046 0 20-12 20-12s-8.954-12-20-12S4 24 4 24s8.954 12 20 12Z" /><path d="M24 29a5 5 0 1 0 0-10a5 5 0 0 0 0 10Z" /></g></svg>
// }

interface IAdminHeaderProps {
  title?: string;
  saveAction?: VoidFunction;
  openPreview?: VoidFunction;
}

const AdminHeader = (props: IAdminHeaderProps) => {
  const { title = "Admin Panel", saveAction, openPreview } = props;
  const location = useLocation();
  const titleName = useMemo(() => {
    return (
      sideNavList.find((nav) => nav.path === location.pathname)?.label || title
    );
  }, [location.pathname]);

  return (
    <AppBar position="fixed" open>
      <Typography
        variant="h6"
        noWrap
        component="div"
        style={{
          margin: 0,
          padding: "8px 24px",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {titleName}
        <div style={{ display: 'flex', gap: '3rem', alignItems: 'center' }}>

          {openPreview &&

            <FontAwesomeIcon icon={faEye}
              onClick={openPreview}
              className={style.previewIcon}

            />
          }
          {saveAction && (
            <div
              className={style.saveBtn}
              style={{ cursor: "pointer" }}
              onClick={saveAction}
            >
              Save
            </div>
          )}</div>
      </Typography>
    </AppBar>
  );
};

export default AdminHeader;
