import Http from "./Http";

const http = new Http();

export default class x7cadService {
  static getx7cad() {
    return http.get<any>({
      endpoint: `/heart/x7cad/`,
    });
  }
  static postx7cad(args: any) {
    return http.post<any>({
      endpoint: `/heart/x7cad/`,
      payload: args,
    });
  }
  static patchx7cad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/x7cad/${id}/`,
      payload: args,
    });
  }
}
