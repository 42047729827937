import React, { useState } from "react";
import { Field } from "./types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import style from "./style.module.scss";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface ReusableFormProps {
  fields: Field[];
  onSubmit: (data: { [key: string]: any }) => void;
}

const ReusableForm: React.FC<ReusableFormProps> = ({ fields, onSubmit }) => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (fieldId: string, value: any) => {
    setFormData({
      ...formData,
      [fieldId]: value,
    });

    setErrors((prev) => ({
      ...prev,
      [fieldId]: "", // Clear error when user starts typing
    }));
  };

  const handleFileChange = (fieldId: string, files: FileList | null) => {
    if (files && files[0]) {
      setFormData({
        ...formData,
        [fieldId]: files[0], // Store the file in formData
      });
    }
  };

  const handleSelectChange = (fieldId: string, value: any) => {
    handleChange(fieldId, value);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    fields.forEach((field) => {
      if (field.required && !formData[field.id] && field.type !== 'file') {
        newErrors[field.id] = `${field.label} is required`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const renderField = (field: Field) => {
    switch (field.type) {
      case "text":
        return (
          <FormControl key={field.id} fullWidth error={!!errors[field.id]}>
            <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
            <OutlinedInput
              id={field.id}
              value={formData[field.id] || ""}
              onChange={(e) => handleChange(field.id, e.target.value)}
              label={field.label}
            />
            {errors[field.id] && (
              <FormHelperText>{errors[field.id]}</FormHelperText>
            )}
          </FormControl>
        );
      case "date":
        return (
          <FormControl key={field.id} fullWidth error={!!errors[field.id]}>
            <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
            <OutlinedInput
              id={field.id}
              type="date"
              value={formData[field.id] || ""}
              onChange={(e) => handleChange(field.id, e.target.value)}
              label={field.label}
            />
            {errors[field.id] && (
              <FormHelperText>{errors[field.id]}</FormHelperText>
            )}
          </FormControl>
        );
      case "time":
        return (
          <FormControl key={field.id} fullWidth error={!!errors[field.id]}>
            <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
            <OutlinedInput
              id={field.id}
              type="time"
              value={formData[field.id] || ""}
              onChange={(e) => handleChange(field.id, e.target.value)}
              label={field.label}
            />
            {errors[field.id] && (
              <FormHelperText>{errors[field.id]}</FormHelperText>
            )}
          </FormControl>
        );
      case "textarea":
        return (
          <FormControl key={field.id} fullWidth error={!!errors[field.id]}>
            <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
            <OutlinedInput
              id={field.id}
              multiline
              rows={4}
              value={formData[field.id] || ""}
              onChange={(e) => handleChange(field.id, e.target.value)}
              label={field.label}
            />
            {errors[field.id] && (
              <FormHelperText>{errors[field.id]}</FormHelperText>
            )}
          </FormControl>
        );
      case "select":
        return (
          <FormControl key={field.id} fullWidth error={!!errors[field.id]}>
            <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.id}-label`}
              id={field.id}
              value={formData[field.id] || ""}
              onChange={(e) => handleSelectChange(field.id, e.target.value)}
              label={field.label}
              multiple={field.multiple} // Support multiple select if defined
            >
              {field.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors[field.id] && (
              <FormHelperText>{errors[field.id]}</FormHelperText>
            )}
          </FormControl>
        );
      case "file":
        return (
          <Button
            key={field.id}
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            {field.label}
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => handleFileChange(field.id, e.target.files)}
            />
          </Button>
        );
      case "button":
        return (
          <Stack key={field.id} direction="row" spacing={2}>
            {field.buttons?.map((button, idx) => (
              <Button
                key={idx}
                variant="outlined"
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.label}
              </Button>
            ))}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ "& > :not(style)": { m: 1 } }}
      noValidate
      autoComplete="off"
    >
      {fields.map((field) => renderField(field))}
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={style.modelButton}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ReusableForm;
