import React, { useEffect, useState } from "react";
import pamcService from "../../../common/services/Pamc";
import Preview from "../../../common/components/Dialog/Preview";
import CustomEditor from "../../../common/components/CustomEditor";
import { toast } from "react-toastify";

const PAMC = () => {
  const [pamcData, setpamcData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!pamcData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      pamcService.patchPAMC(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          toast.error('Failed to save!')
        });
    } else {
      pamcService.postPAMC(payload)
        .then((response: any) => {
          toast.success("Saved successfully!")

        })
        .then((error: any) => {
          toast.error('Failed to save!')
        });
    }
  };

  useEffect(() => {
    pamcService.getPAMC()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setpamcData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, []);

  return (
    <div style={{ marginTop: "3.5rem" }}>
      <CustomEditor submitHandler={handleSubmit} data={pamcData} handleOpenPreview={handleOpenPreview} />
      <Preview title="Packsize Approved Marketing Content" open={openPreview} onClose={handleClosePreview} blocks={pamcData} />
    </div>
  );
};

export default PAMC;
