import React from "react";
import GritPartnerService from "../../../common/services/GritPartnerService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

const GritPartnerForm = () => {
  // Static data for project_grit options
  const projectGritOptions = [
    { value: "8b524d24-fea1-46f8-bbdf-542ead5fcc47", label: "Project A" },
    { value: "e20b4d72-d62c-4a91-b0e1-d750d95c727d", label: "Project B" },
    // Add more options as needed
  ];

  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      name: data.name,
      project_grit: data.project_grit,
    };

    GritPartnerService.postPartner(payload)
      .then((response: any) => {
        toast.success("GritPartner saved successfully!");
      })
      .catch((error: any) => {
        toast.error("Failed to save GritPartner!");
      });
  };

  const formFields: Field[] = [
    {
      id: "name",
      type: "text" as const,
      label: "Name",
      required: true,
    },
    {
      id: "project_grit",
      type: "select" as const,
      label: "Project Grit",
      options: projectGritOptions,
      required: true,
    },
  ];

  return (
    <>
      <div>
        <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default GritPartnerForm;
