import React, { useState, ChangeEvent, FormEvent } from "react";

import { BlobServiceClient, RestError } from "@azure/storage-blob";

const containerName = "patnerportal";

const conn_string =
  "https://isgeneral.blob.core.windows.net/partnerportal?sp=racwdli&st=2023-10-07T15:39:37Z&se=2027-02-28T23:39:37Z&spr=https&sv=2022-11-02&sr=c&sig=14%2FpqVCbLMqK7iWlmVxTH6Avd0yGVppcr%2BPouF0z6d4%3D";

function TestFileUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
    }
  };

  const uploadVideoToAzureBlob = async () => {
    console.log(selectedFile, " Selected file");
    return;
    if (!selectedFile) return;
    const blobServiceClient = new BlobServiceClient(conn_string);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobName = selectedFile?.name;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName ?? "");

    // try {
    //     // Read the selected file as an array buffer.\
    //     await blockBlobClient.uploadData(selectedFile);
    //   } catch (error) {
    //     if (error instanceof RestError) {
    //       console.error('Error uploading video:', error.message);
    //     } else {
    //       console.error('Error uploading video:', error);
    //     }
    //   }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    await uploadVideoToAzureBlob();
  };

  return (
    <div>
      <h1>File Upload</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}

export default TestFileUpload;
