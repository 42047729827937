import Http from "./Http";

const http = new Http();

export default class pamcService {
  static getPAMC() {
    return http.get<any>({
      endpoint: `/heart/pamc/`,
    });
  }
  static postPAMC(args: any) {
    return http.post<any>({
      endpoint: `/heart/pamc/`,
      payload: args,
    });
  }
  static patchPAMC(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/pamc/${id}/`,
      payload: args,
    });
  }
}
