import Http from "./Http";

const http = new Http();

export default class PpoService {
  static getPPO() {
    return http.get<any>({
      endpoint: `/heart/ppo/`,
    });
  }
  static postPPO(args: any) {
    return http.post<any>({
      endpoint: `/heart/ppo/`,
      payload: args,
    });
  }
  static patchPPO(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/ppo/${id}/`,
      payload: args,
    });
  }
}
