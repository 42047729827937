import React, { useState } from "react";
import BoDTagService from "../../../common/services/bodTagService";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

interface BoDTagFormProps {
  onTagAdded: () => void; // Callback to notify parent component
}

const BoDTagForm: React.FC<BoDTagFormProps> = ({ onTagAdded }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (data: { [key: string]: any }) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await BoDTagService.postTag({ tag_name: data.tag_name });
      setSuccess("Tag created successfully!");
      onTagAdded(); // Notify parent component of successful tag addition
    } catch (err) {
      setError("Failed to create tag. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formFields: Field[] = [
    {
      id: "tag_name",
      type: "text" as const,
      label: "Tag Name",
      required: true,
    },
  ];

  return (
    <div>
      <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </div>
  );
};

export default BoDTagForm;
