
import {
    createContext,
    useState,
    useContext,
    ReactNode,
  } from 'react';

  
  const CurrentUser = createContext({});
  
  interface IProps {
    children: ReactNode;
  }
  
  export const CurrentUserProvider = ({ children }: IProps) => {
    const [currentUser, setCurrentUser] = useState<any>();
  
    const setValues = (args:any) => {
      setCurrentUser(args);
    };
  
    return (
      <CurrentUser.Provider
        value={{
          currentUser,
          setValues,
        }}
      >
        {children}
      </CurrentUser.Provider>
    );
  };
  
  export const useCurrentUser: any = () => useContext(CurrentUser);