import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Auth from "../../../common/services/UserService";
import "./styles.css";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";

const formatDateTime = (dateTime: string | number | Date) => {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const UserTable = () => {
  const [users, setusers] = useState([]);

  useEffect(() => {
    Auth.getUserList("/api/v1/account/")
      .then((response) => {
        setusers(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Try to parse the response as JSON
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <AdminHeader title="Users" />

      <div style={{ display: "flex", alignItems: "center",justifyContent: "space-between" }}>
        <h1>User List</h1>
        <Link to={`/admin/adduser`}>
          <div className="addbtn">
            <Button variant="contained" color="primary">
              <span className="symbol">+</span> User
            </Button>
          </div>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="t_bold">First Name</TableCell>
              <TableCell className="t_bold">Last Name</TableCell>
              <TableCell className="t_bold">Email Address</TableCell>
              <TableCell className="t_bold">Partner Name</TableCell>
              <TableCell className="t_bold">Created At</TableCell>
              <TableCell className="t_bold">Updated At</TableCell>
              <TableCell className="t_bold">Status</TableCell>
              <TableCell className="t_bold">Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((partner: any) => (
              <TableRow key={partner.id}>
                <TableCell>{partner.first_name}</TableCell>
                <TableCell>{partner.last_name}</TableCell>
                <TableCell>{partner.email}</TableCell>
                <TableCell>{partner.partner?.name ?? ""}</TableCell>
                <TableCell>{formatDateTime(partner.created_at)}</TableCell>
                <TableCell>{formatDateTime(partner.updated_at)}</TableCell>
                <TableCell>
                  {partner.is_active ? "Active" : "Inactive"}
                </TableCell>
                <TableCell>{formatDateTime(partner.last_login)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserTable;
