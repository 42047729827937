import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { IBlocks } from ".";
import PdfParser from "../PdfParser";
import videojs from "video.js";
import VideoJs from "../VideoPlayer/VideoJs";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getToken } from "../../utils/token";
import "./CustomParser.css";
interface Iprops {
  block: IBlocks;
}

const CustomParser = (props: Iprops) => {
  const { block } = props;
  const [loadVideoStatus, setLoadVideoStatus] = useState(Boolean);
  let m3u8Url = `https://api.partners.iwengineering.com/media/uploads/processed/${block?.video?.identifier}/720p/720p.m3u8`;
  // let m3u8Url = `http://localhost:8000/media/uploads/processed/${
  //   block?.video?.identifier
  // }/720p/720p.m3u8?token=${getToken({ name: "token" })}`;

  useEffect(() => {
    if (block && block.type === "video") {
      // m3u8Url = `http://localhost:8000/media/uploads/processed/${
      //   block?.video?.identifier
      // }/720p/720p.m3u8?token=${getToken({ name: "token" })}`;
      m3u8Url = `https://api.partners.iwengineering.com/media/uploads/processed/${block?.video?.identifier}/720p/720p.m3u8`;
    }
  }, [block]);
  useEffect(() => {
    if (block && block.type === "video") {
      axios
        .get(
          `https://api.partners.iwengineering.com/media/uploads/processed/${
            block?.video?.identifier
          }/720p/720p.m3u8?token=${getToken({ name: "token" })}`,
          {
            headers: {
              Authorization: `Token ${getToken({
                name: "token",
              })}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setLoadVideoStatus(true);
          } else {
            setLoadVideoStatus(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
  }, [block]);

  const playerRef = React.useRef(null);

  const vOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 720,
    height: 300,
    controls: true,
    sources: [
      {
        src: m3u8Url,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <>
      {block && (
        <>
          {block.type === "text" && (
            <>
              <h2 className="section-header">{block.header}</h2>
              <h4 className="section-title">{block.title}</h4>
              {parse(block.data)}
            </>
          )}
        </>
      )}

      {block && (
        <>
          {block.type === "document" && (
            <>
              <h2 className="section-header">{block.header}</h2>
              <h4 className="section-title">{block.title}</h4>
              <PdfParser
                pdf={`${block.data}?token=${getToken({ name: "token" })}`}
              />
            </>
          )}
        </>
      )}
      {block && (
        <>
          {block.type === "image" && (
            <>
              <h2 className="section-header">{block.header}</h2>
              <h4 className="section-title">{block.title}</h4>
              <div className="imageVdo-section">
                <img
                  src={`${block.data}?token=${getToken({ name: "token" })}`}
                  alt={block.title}
                  // style={{ width: '300px',height:'200px' }}
                />
              </div>
            </>
          )}
        </>
      )}

      {block && (
        <>
          {block.type === "video" && (
            <>
              <h2 className="section-header">{block.header}</h2>
              <h4 className="section-title">{block.title}</h4>
              {loadVideoStatus ? (
                !!m3u8Url &&
                !!playerRef && (
                  <div className="imageVdo-section">
                    <VideoJs options={vOptions} ref={playerRef} />
                  </div>
                )
              ) : (
                // <VideoJs ref={playerRef} {...vOptions} />
                <div>
                  <Card
                    sx={{ minWidth: 275 }}
                    style={{
                      backgroundColor: "#cfcecd",
                      height: 300,
                      width: 720,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: 25,
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Video is still processing...
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </>
      )}

      {block && (
        <>
          {block.type === "embed" && (
            <>
              <h2 className="section-header">{block.header}</h2>
              <h4 className="section-title">{block.title}</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {parse(block.data)}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CustomParser;
