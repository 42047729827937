import React, { useState } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import GritPartnerForm from "./GritPartnerForm"; // Import the GritPartnerForm component

const columns: Column[] = [
    { id: "name", label: "Name", minWidth: 150, align: "left" },
    { id: "project_grit", label: "Project Grit", minWidth: 250, align: "left" },
  ];
  
  const initialRows = [
    { id: 1, name: "Partner A", project_grit: "8b524d24-fea1-46f8-bbdf-542ead5fcc47" },
    { id: 2, name: "Partner B", project_grit: "7a5b6c77-efa2-46a8-bbdf-542e9f5bcb29" },
  ];

const GritPartnerView = () => {
  const [rows, setRows] = useState(initialRows);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPartnerModal, setOpenPartnerModal] = useState(false); // State for Partner modal
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClosePartnerModal = () => {
    setOpenPartnerModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === editData.id ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Grit Partners</div>
          <div>
            <Button 
              onClick={() => setOpenPartnerModal(true)}
              className={style.openModel}
            >
              Add Grit Partner
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Grit Partner</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={editData?.name || ""}
              onChange={handleChange}
            />
            <TextField
              label="Contact"
              name="contact"
              fullWidth
              margin="normal"
              value={editData?.contact || ""}
              onChange={handleChange}
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              fullWidth
              margin="normal"
              value={editData?.email || ""}
              onChange={handleChange}
            />
            <TextField
              label="Address"
              name="address"
              fullWidth
              margin="normal"
              value={editData?.address || ""}
              onChange={handleChange}
            />
            <TextField
              label="Partnership Date"
              name="partnershipDate"
              type="date"
              fullWidth
              margin="normal"
              value={editData?.partnershipDate || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this Grit Partner?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="secondary" className={style.modelButton}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPartnerModal}
          onClose={handleClosePartnerModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Add Grit Partner
            <IconButton
              aria-label="close"
              onClick={handleClosePartnerModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <GritPartnerForm /> {/* Render the GritPartnerForm component */}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default GritPartnerView;
