import React, { useEffect, useState } from "react";
import DurationTuitionService from "../../../common/services/DurationTuitionService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm"; // Import the reusable form
import { Field } from "../FormComponent/types";

const DurationTuitionForm = () => {
  const [durationOptions, setDurationOptions] = useState<{ value: string, label: string }[]>([]);
  const [tuitionOptions, setTuitionOptions] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    // Fetch durations and tuitions from the API or define them here
    const fetchOptions = () => {
      setDurationOptions([
        { value: "d1", label: "Duration 1" },
        { value: "d2", label: "Duration 2" },
        { value: "d3", label: "Duration 3" },
      ]);

      setTuitionOptions([
        { value: "t1", label: "Tuition 1" },
        { value: "t2", label: "Tuition 2" },
        { value: "t3", label: "Tuition 3" },
      ]);
    };

    fetchOptions();
  }, []);

  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      duration: data.duration,
      tuition: data.tuition,
    };

    DurationTuitionService.postDurationTuition(payload)
      .then((response: any) => {
        toast.success("Duration Tuition saved successfully!");
      })
      .catch((error: any) => {
        toast.error("Failed to save Duration Tuition!");
      });
  };

  const formFields: Field[] = [
    {
      id: "duration",
      type: "select" as const,
      label: "Duration",
      required: true,
      options: durationOptions, // Populated from the state
    },
    {
      id: "tuition",
      type: "select" as const,
      label: "Tuition",
      required: true,
      options: tuitionOptions, // Populated from the state
    },
  ];

  return (
    <div>
      <ReusableForm fields={formFields} onSubmit={handleSubmit} />
    </div>
  );
};

export default DurationTuitionForm;
