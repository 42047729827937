import Http from "./Http";

const http = new Http();

export default class GritPartnerService {
  static getPartners() {
    return http.get<any>({
      endpoint: `/grit-partner/`,
    });
  }

  static postPartner(args: any) {
    return http.post<any>({
      endpoint: `/grit-partner/`,
      payload: args,
    });
  }

  static getPartner(uuid: string) {
    return http.get<any>({
      endpoint: `/grit-partner/${uuid}/`,
    });
  }

  static deletePartner(uuid: string) {
    return http.delete<any>({
      endpoint: `/grit-partner/${uuid}/`,
    });
  }
}
