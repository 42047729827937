import Http from "./Http";

const http = new Http();

export default class autogluercadService {
  static getautogluercad() {
    return http.get<any>({
      endpoint: `/heart/autogluercad/`,
    });
  }
  static postautogluercad(args: any) {
    return http.post<any>({
      endpoint: `/heart/autogluercad/`,
      payload: args,
    });
  }
  static patchautogluercad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/autogluercad/${id}/`,
      payload: args,
    });
  }
}
