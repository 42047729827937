import { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useCurrentUser } from "../../common/context/CurrentUser";
import Auth from "../../common/services/UserService";
import auth from "../../config/auth";
import routes from "../../config/routes";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import pamcIcon from "../../assets/OverviewIcon/PAMC.png";
import pbcrIcon from "../../assets/OverviewIcon/PBCR.png";
import pcsIcon from "../../assets/OverviewIcon/PCS.png";
import piorIcon from "../../assets/OverviewIcon/PIOR.png";
import ppoIcon from "../../assets/OverviewIcon/PPOTV.png";
import ppsfIcon from "../../assets/OverviewIcon/PPSF.png";
import ppsrgIcon from "../../assets/OverviewIcon/PPSRG.png";
import ppssIcon from "../../assets/OverviewIcon/PPSS.png";
import ppseIcon from "../../assets/OverviewIcon/PSSE.png";
import logout from "../../assets/UserIcon/logout.png";

import cad from "../../assets/UserIcon/cad.png";
import partner from "../../assets/UserIcon/partner.png";
import user from "../../assets/UserIcon/user.png";
import packsize from "./packsize_img.jpg";
import gifted_logo from "./gifted_logo.svg";


import { Tooltip } from "@mui/material";
import "./styles.css";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  minHeight: "64px",
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  // flexShrink: 0,
  whiteSpace: "nowrap",
  fontSize: "20px",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const sideNavList = [
  // {
  //   name: "PPO",
  //   icon: ppoIcon,
  //   label: "Packsize & Portal Overview",
  //   path: "/admin/ppo",
  // },
  // {
  //   name: "PPSS",
  //   icon: ppssIcon,
  //   label: "Packsize Product Sale & Spec Sheets",
  //   path: "/admin/ppss",
  // },
  // {
  //   name: "PPOTV",
  //   icon: ppoIcon,
  //   label: "Packsize Product Overview & Training Videos",
  //   path: "/admin/ppotv",
  // },
  // {
  //   name: "PPSRG",
  //   icon: ppsrgIcon,
  //   label: "Packsize Platform Solutioning Resource Guides",
  //   path: "/admin/ppsrg",
  // },
  // {
  //   name: "PSSE",
  //   icon: ppseIcon,
  //   label: "Packsize Standard Solution Examples",
  //   path: "/admin/psse",
  // },
  // {
  //   name: "PCS",
  //   icon: pcsIcon,
  //   label: "Packsize Customer Spotlights",
  //   path: "/admin/pcs",
  // },
  // {
  //   name: "PIOR",
  //   icon: piorIcon,
  //   label: "Packsize Installation and Operation Resources",
  //   path: "/admin/pior",
  // },
  // {
  //   name: "PBCR",
  //   icon: pbcrIcon,
  //   label: "Packsize Qualification & Business Case Resources",
  //   path: "/admin/pbcr",
  // },
  // {
  //   name: "PAMC",
  //   icon: pamcIcon,
  //   label: "Packsize Approved Marketing Content",
  //   path: "/admin/pamc",
  // },
  // {
  //   name: "PPSF",
  //   icon: ppsfIcon,
  //   label: "Packsize Project Submission Form",
  //   path: "/admin/ppsf",
  // },
  {
    name: "BOD Designation",
    icon: ppsfIcon,
    label: "BOD Designation",
    path: "/admin/bodDesign",
  },
  {
    name: "BOD Personal",
    icon: ppsfIcon,
    label: "BOD Personal",
    path: "/admin/bodPersonal",
  },
  {
    name: "BOD Tag",
    icon: ppsfIcon,
    label: "BOD Tag",
    path: "/admin/bodTag",
  },
  {
    name: "Tag Member",
    icon: ppsfIcon,
    label: "Tag Member",
    path: "/admin/tagMember",
  },
  {
    name: "Duration",
    icon: ppsfIcon,
    label: "Duration",
    path: "/admin/duration",
  },
  {
    name: "Tution",
    icon: ppsfIcon,
    label: "Tution",
    path: "/admin/tution",
  },
  // {
  //   name: "DurationTution",
  //   icon: ppsfIcon,
  //   label: "DurationTution",
  //   path: "/admin/durationTution",
  // },
  {
    name: "Event",
    icon: ppsfIcon,
    label: "Event",
    path: "/admin/event",
  },
  {
    name: "Project GRIT",
    icon: ppsfIcon,
    label: "Project GRIT",
    path: "/admin/projectGrit",
  },
  {
    name: "GRIT Partner",
    icon: ppsfIcon,
    label: "GRIT Partner",
    path: "/admin/gritPartner",
  },

];

const AdminLayout = () => {
  const isAuthed = true;
  const { currentUser, setValues } = useCurrentUser();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("");
  const [currentPage, setCurrentPage] = useState(sideNavList[0].label);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  let group: string = currentUser?.groups[0];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (itemName: string) => {
    setOpen(true);
    setSelectedItem(itemName);
    setCurrentPage(itemName);
    localStorage.setItem("currentPage", itemName);
    setCurrentPage(itemName);
    localStorage.setItem("currentPage", itemName);
  };
  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  // useEffect(() => {
  //   const handleOutsideClick = (e:any) => {
  //     if (isDropdownOpen && !e.target.closest(".dropdown")) {
  //       setIsDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener("click", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, [isDropdownOpen]);

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(savedPage);
    } else {
      setCurrentPage(sideNavList[0].label);
    }
  }, []);

  const location = useLocation();
  const isSelected = (path: string) => {
    return location.pathname === path;
  };

  useEffect(() => {
    // if (isAuthed) {
    //   Auth.getUser("me")
    //     .then((response: any) => {
    //       setValues({
    //         username: response?.username,
    //         first_name: response?.first_name,
    //         middle_name: response?.middle_name,
    //         last_name: response?.last_name,
    //         groups: response?.groups,
    //         profile_picture: response?.profile_pictzure,
    //         email: response?.profile_picture,
    //         display_name: response?.display_name,
    //       });
    //     })
    //     .catch(() => {
    //       navigate(routes.login.path);
    //     });
    // }
  }, [isAuthed, navigate]);

  return isAuthed ? (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* <AppBar position="fixed" open={open}>
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              ></IconButton>
              <Typography variant="h6" noWrap component="div">
                {selectedItem || currentPage}
              </Typography>
            </div>

          </Toolbar>
        </AppBar> */}
        <Drawer variant="permanent" open={open}>
          <img src={gifted_logo} alt="Packsize" className="p_image" />
          <DrawerHeader>
            <></>
          </DrawerHeader>
          <Divider />
          <List>
            {sideNavList.map((text, index) => (
              <Tooltip
                title={text?.label
                  ?.toLocaleLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                key={index}
                placement="right-end"
                arrow
              >
                <Link
                  to={text.path}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{
                      display: "block",
                      backgroundColor: isSelected(text.path)
                        ? "#E0DD93"
                        : "inherit",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: "30px",
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => handleDrawerClose(text.label)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={text?.icon}
                          alt={text?.name}
                          height={20}
                          width={20}
                        />
                      </ListItemIcon>
                      <div style={{ fontSize: '0.9rem', fontWeight: 400 }}>{text.name}</div>
                      {/* <ListItemText
                        primary={text?.name}
                        sx={{ opacity: open ? 1 : 0 , fontSize: 10}}
                      /> */}
                    </ListItemButton>
                  </ListItem>
                </Link>
              </Tooltip>
            ))}
          </List>
          <Divider />
          <List>
            {[
              // {

              //   name: "CADs",

              //   icon: <img src={cad} width={20} />,

              //   path: `/admin/cads`,
              // },
              {

                name: "Users",

                icon: <img src={user} width={20} />,

                path: `/admin/users`,
              },
              // {

              //   name: "Partners",

              //   icon: <img src={partner} width={20} />,

              //   path: `/admin/partners`,
              // },
            ].map((item, index) => (
              <Tooltip
                title={item.name
                  ?.toLocaleLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                key={index}
                placement="right-end"
                arrow
              >
                <Link
                  to={item.path}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor: isSelected(item.path)
                          ? "#E0DD93"
                          : "inherit",
                      }}
                      onClick={() => handleDrawerClose(item.name)}
                    >
                      <ListItemIcon
                        sx={{
                          display: "block",
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <div style={{ fontSize: '0.9rem', fontWeight: 400 }}>{item.name}</div>
                    </ListItemButton>
                  </ListItem>
                </Link>
              </Tooltip>
            ))}
          </List>
          <Divider />
          {isAuthed && (
            // <div className="btn-container">
            //   <Button variant="contained" color="primary" className="logout_btn"
            // onClick={() => {
            //   localStorage.removeItem("token");
            //   navigate("/login");
            // onClick={() => {
            //   localStorage.removeItem("token");
            //   navigate("/login");
            //     }}
            //   >
            //     Logout
            //   </Button>
            // </div>
            <div
              className="box"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("currentPage");
                <div
                  className="box"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("currentPage");

                    navigate("/");
                  }}
                >
                  <img src={logout} className="exit_img" />
                  <span className="text">LogOut</span>
                </div>
                navigate("/");
              }}
            >
              <img src={logout} className="exit_img" />
              <span style={{ fontSize: "0.9rem", fontWeight: 400 }} className="logout_label">LogOut</span>
            </div>
          )}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  ) : (
    <Navigate to="/register" replace />
  );
};

export default AdminLayout;