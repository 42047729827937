import React, { useState } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import ProjectGritForm from "./ProjectGritForm";

const columns: Column[] = [
  { id: "description_one", label: "Description One", minWidth: 170, align: "left" },
  { id: "description_two", label: "Description Two", minWidth: 170, align: "left" },
  { id: "description_three", label: "Description Three", minWidth: 170, align: "left" },
];

const initialRows = [
  {
    id: 1,
    description_one: "Description for Project 1 - One",
    description_two: "Description for Project 1 - Two",
    description_three: "Description for Project 1 - Three",
  },
  {
    id: 2,
    description_one: "Description for Project 2 - One",
    description_two: "Description for Project 2 - Two",
    description_three: "Description for Project 2 - Three",
  },
  // Add more rows as needed
];

const ProjectGritView = () => {
  const [rows, setRows] = useState(initialRows);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false); // State to manage the modal
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === editData.id ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Project Grit</div>
          <div onClick={() => setOpenModal(true)} className={style.openModel}>
            Add Project
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Project</DialogTitle>
          <DialogContent>
            <TextField
              label="Description One"
              name="description_one"
              fullWidth
              margin="normal"
              value={editData?.description_one || ""}
              onChange={handleChange}
            />
            <TextField
              label="Description Two"
              name="description_two"
              fullWidth
              margin="normal"
              value={editData?.description_two || ""}
              onChange={handleChange}
            />
            <TextField
              label="Description Three"
              name="description_three"
              fullWidth
              margin="normal"
              value={editData?.description_three || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this project?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="secondary"
              className={style.modelButton}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Project Grit Form
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ProjectGritForm />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ProjectGritView;
