import React from "react";
import ProjectGritService from "../../../common/services/ProjectGrit";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

const ProjectGritForm = () => {
  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      description_one: data.description_one,
      description_two: data.description_two,
      description_three: data.description_three,
    };

    ProjectGritService.postProject(payload)
      .then((response: any) => {
        toast.success("Project saved successfully!");
      })
      .catch((error: any) => {
        toast.error("Failed to save project!");
      });
  };

  const formFields: Field[] = [
    {
      id: "description_one",
      type: "text" as const,
      label: "Description One",
      required: true,
    },
    {
      id: "description_two",
      type: "text" as const,
      label: "Description Two",
      required: true,
    },
    {
      id: "description_three",
      type: "text" as const,
      label: "Description Three",
      required: true,
    },
  ];

  return (
    <>
      <div>
        <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default ProjectGritForm;
