import { Button, Container, Popover, TextField, styled } from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
import config from "../../../config";
import FormDialog from "../FormDialog";
import RichTextEditor from "../RichTextEditor";
import Action from "./Action";
import "./styles.css";
import {
  CodeOffOutlined,
  FormatIndentDecrease,
  ImageOutlined,
  PictureAsPdfOutlined,
  SmartDisplayOutlined,
} from "@mui/icons-material";
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import { FileService } from "../../services/File";
import { getToken } from "../../utils/token";
import CustomParser from "./CustomParser";
type blockType = "text" | "video" | "document" | "embed" | "image";

export type IBlocks = {
  index: number;
  type: blockType;
  header: string;
  title: string;
  data: string;
  desc: string;
  saved: boolean;
  video?: {
    identifier: string;
  };
};

interface Iprops {
  submitHandler: (data: string) => void;
  data: IBlocks[];
  handleOpenPreview?: () => void;
}

const CustomEditor = (props: Iprops) => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const { data, submitHandler, handleOpenPreview } = props;
  const imageRef = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const documentRef = useRef<any>(null);
  const embedRef = useRef<any>(null);
  const [blocks, setBlocks] = useState<IBlocks[]>(data);
  const [blocksToSave, setBlocksToSave] = useState<IBlocks[]>(data);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [file, setFile] = useState();
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [openVideoUpload, setOpenVideoUpload] = useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = useState(false);
  const [openEmbed, setOpenEmbed] = useState(false);
  const [openPreviewBlock, setOpenPreviewBlock] = useState(false);
  const [block, setCurrentBlock] = useState<IBlocks | undefined>();
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const [insertIndex, setInsertIndex] = useState<number | null>(null);
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState("");
  const [desc, setDesc] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    setBlocks(data || []);
    setBlocksToSave(data || []);
  }, [data]);

  const ids = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnClick = () => {
    setShow(!show);
  };
  const onRichTextClick = (index: number | null = null) => {
    if (isEdit && block) {
      const updatedBlocks = [...blocks];
      const changeIndex = blocks.findIndex(
        (b: IBlocks) => b.index === block.index
      );
      if (title) {
        updatedBlocks[changeIndex].title = title;
      }
      if (desc) {
        updatedBlocks[changeIndex].desc = desc;
      }
      if (header) {
        updatedBlocks[changeIndex].header = header;
      }
      setBlocks(updatedBlocks);
      handleClearStates();
      return null;
    } else {
      setIsAdd(true);
      if (index != null) {
        const currentBlocks = [...blocks];
        const blockToInsert: IBlocks = {
          index: new Date().getTime(),
          title: title,
          header: header,
          desc: desc,
          type: "text",
          data: "<p><p/>",
          saved: false,
        };
        currentBlocks.splice(index + 1, 0, blockToInsert);
        setBlocks(currentBlocks);
        setCurrentBlock(blockToInsert);
      } else {
        const blockToInsert: IBlocks = {
          index: new Date().getTime(),
          title: title,
          header: header,
          desc: desc,
          type: "text",
          data: "<p><p/>",
          saved: false,
        };
        setBlocks([...blocks, blockToInsert]);
        setCurrentBlock(blockToInsert);
      }
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearStates = () => {
    setTitle("");
    setDesc("");
    setHeader("");
  };
  const updateSavedState = (state: boolean, index: number) => {
    const changeIndex1 = blocks.findIndex(
      (block: IBlocks) => block.index === index
    );
    const changeIndex2 = blocksToSave.findIndex(
      (block: IBlocks) => block.index === index
    );
    blocks[changeIndex1].saved = state;
    if (changeIndex2 && blocksToSave[changeIndex2]) {
      blocksToSave[changeIndex2].saved = state;
      setBlocksToSave(blocksToSave);
    }

    setBlocks(blocks);
  };
  useEffect(() => {
    if (isEdit) {
      handleClearStates();
    }
    if (isEdit && block) {
      updateSavedState(false, block.index);
    }
  }, [isEdit, block]);
  const handleBlockLevelSave = async (
    block: IBlocks | undefined,
    index: number
  ) => {
    let blockIfExists;
    if (blocksToSave) {
      blockIfExists = blocksToSave.find(
        (b: IBlocks) => b.index === block?.index
      );
    }
    if (blockIfExists && block) {
      const updatedBlocks = [...blocksToSave];
      const changeIndex = updatedBlocks.findIndex(
        (b: IBlocks) => b.index === block.index
      );
      updatedBlocks[changeIndex] = block; // Update the block
      await setBlocksToSave(updatedBlocks);
      await updateSavedState(true, block.index);
      await submitHandler(
        JSON.stringify({
          data: updatedBlocks,
        })
      );
      await updateSavedState(true, block.index);
      setIsEdit(false);
    } else if (!blockIfExists && block) {
      const currentBlocksToSave = [...blocksToSave];
      const lastIndex = blocksToSave.length - 1;
      if (index < lastIndex) {
        currentBlocksToSave.splice(index, 0, block);
        await setBlocksToSave(currentBlocksToSave);
        await updateSavedState(true, block.index);
        await submitHandler(
          JSON.stringify({
            data: currentBlocksToSave,
          })
        );
      } else {
        currentBlocksToSave.push(block);
        await setBlocksToSave(currentBlocksToSave);
        await updateSavedState(true, block.index);
        await submitHandler(
          JSON.stringify({
            data: currentBlocksToSave,
          })
        );
      }
      setIsEdit(false);
    }
    setIsAdd(false);
  };
  const onFileSubmitHandler = (
    type: blockType,
    index: number | null = null
  ) => {
    if (!file) {
      if (isEdit && block) {
        const updatedBlocks = [...blocks];
        const changeIndex = blocks.findIndex(
          (b: IBlocks) => b.index === block.index
        );
        if (title) {
          updatedBlocks[changeIndex].title = title;
        }
        if (desc) {
          updatedBlocks[changeIndex].desc = desc;
        }
        if (header) {
          updatedBlocks[changeIndex].header = header;
        }
        setBlocks(updatedBlocks);
        setOpenDocumentUpload(false);
        setOpenVideoUpload(false);
        setOpenImageUpload(false);
        handleClearStates();

        return null;
      }
    }
    if (file) {
      let fileURL = "";
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(config.apiUrl + "commons/file/", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Important: Set the content type to multipart/form-data
            Authorization: `Token ${getToken({
              name: "token",
            })}`,
          },
        })
        .then((response) => {
          fileURL = response.data?.file;

          if (type === "video") {
            const urlObject = new URL(fileURL);

            // Get the last part of the pathname, which is the filename
            const pathnameParts = urlObject.pathname.split("/");
            const filename = pathnameParts[pathnameParts.length - 1];
            FileService.requestVideoChunk({
              file_name_with_ext: filename,
            })
              .then((response: any) => {
                console.log("Done");
              })
              .catch((error: any) => {
                toast.error("Error on video chunk request");
              });
          }

          if (isEdit && block) {
            const updatedBlocks = [...blocks];
            const changeIndex = blocks.findIndex(
              (b: IBlocks) => b.index === block.index
            );
            if (title) {
              updatedBlocks[changeIndex].title = title;
            }
            if (desc) {
              updatedBlocks[changeIndex].desc = desc;
            }
            if (header) {
              updatedBlocks[changeIndex].header = header;
            }
            updatedBlocks[changeIndex].data = fileURL;
            setBlocks(updatedBlocks);
            handleClearStates();
            return null;
          }
          if (index != null) {
            const currentBlocks = [...blocks];
            const blockToInsert: IBlocks = {
              index: new Date().getTime(),
              title: title,
              header: header,
              desc: desc,
              type: type,
              data: fileURL,
              saved: false,
            };
            if (type === "video") {
              const urlObject = new URL(fileURL);
              const pathnameParts = urlObject.pathname.split("/");
              const filenameWithExtension =
                pathnameParts[pathnameParts.length - 1];
              const [baseFilename, _] = filenameWithExtension.split(".");
              blockToInsert["video"] = {
                identifier: baseFilename,
              };
            }
            currentBlocks.splice(index + 1, 0, blockToInsert);
            setBlocks(currentBlocks);
          } else {
            const urlObject = new URL(fileURL);

            // Get the last part of the pathname, which is the filename
            const pathnameParts = urlObject.pathname.split("/");
            const filenameWithExtension =
              pathnameParts[pathnameParts.length - 1];

            // Split the filename by the dot to separate the base filename and extension
            const [baseFilename, _] = filenameWithExtension.split(".");
            if (type === "video") {
              setBlocks([
                ...blocks,
                {
                  type: type,
                  data: fileURL,
                  title: title,
                  header: header,
                  desc: desc,
                  index: new Date().getTime(),
                  saved: false,
                  video: {
                    identifier: baseFilename,
                  },
                },
              ]);
            } else {
              setBlocks([
                ...blocks,
                {
                  type: type,
                  data: fileURL,
                  title: title,
                  header: header,
                  desc: desc,
                  index: new Date().getTime(),
                  saved: false,
                },
              ]);
            }
          }
          toast.success(`${type} uploaded successfully!`);
        })
        .catch((error) => {
          toast.error("Unable to upload file.");
        })
        .finally(() => {
          setFile(undefined);
          setOpenDocumentUpload(false);
          setOpenImageUpload(false);
          setOpenVideoUpload(false);
        });
    }
  };
  const onVideoUpload = (e: any) => {
    const video = e.target.files[0];
    setFile(video);
  };

  const onDocumentUpload = (e: any) => {
    const doc = e.target.files[0];
    setFile(doc);
  };
  const onImageUpload = (e: any) => {
    const img = e.target.files[0];
    setFile(img);
  };
  const handleImageAdd = () => {
    if (imageRef) {
      imageRef.current.click();
    }
  };
  const handleVideoAdd = () => {
    if (videoRef) {
      videoRef.current.click();
    }
  };

  const handleDocumentAdd = () => {
    if (documentRef) {
      documentRef.current.click();
    }
  };
  const handleEmbedAdd = (index: number | null = null) => {
    if (embedRef) {
      if (isEdit && block && block.type === "embed") {
        const updatedBlocks = [...blocks];
        const changeIndex = blocks.findIndex(
          (b: IBlocks) => b.index === block.index
        );
        if (title) {
          updatedBlocks[changeIndex].title = title;
        }
        if (desc) {
          updatedBlocks[changeIndex].desc = desc;
        }
        if (header) {
          updatedBlocks[changeIndex].header = header;
        }
        updatedBlocks[changeIndex].data = embedRef.current.value;
        setOpenEmbed(false);
        setBlocks(updatedBlocks);
        handleClearStates();
        return null;
      }
      if (index != null) {
        const currentBlocks = [...blocks];
        const blockToInsert: IBlocks = {
          type: "embed",
          title: title,
          header: header,
          desc: desc,
          data: embedRef.current.value,
          index: new Date().getTime(),
          saved: false,
        };
        currentBlocks.splice(index + 1, 0, blockToInsert);
        setBlocks(currentBlocks);
      } else {
        setBlocks([
          ...blocks,
          {
            type: "embed",
            title: title,
            header: header,
            desc: desc,
            data: embedRef.current.value,
            index: new Date().getTime(),
            saved: false,
          },
        ]);
      }
    }
    setOpenEmbed(false);
  };
  useEffect(() => {
    if (openEmbed || openImageUpload || openDocumentUpload || openVideoUpload) {
      setAnchorEl(null);
    }
  }, [openImageUpload, openEmbed, openDocumentUpload, openVideoUpload]);
  const handleBlockTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    if (block?.type === "text") {
      const changeIndex = blocks.findIndex(
        (b: IBlocks) => block.index === b.index
      );
      blocks[changeIndex].title = e.target.value;
    }
  };
  const handleSectionHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
    if (block?.type === "text") {
      const changeIndex = blocks.findIndex(
        (b: IBlocks) => block.index === b.index
      );
      blocks[changeIndex].header = e.target.value;
    }
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDesc(event.target.value);
    if (block?.type === "text") {
      const changeIndex = blocks.findIndex(
        (b: IBlocks) => block.index === b.index
      );
      blocks[changeIndex].desc = event.target.value;
    }
  };
  function truncateText(
    text: string | undefined,
    limit: number
  ): string | undefined {
    if (text && text.split(" ").length > limit) {
      return text.split(" ").slice(0, limit).join(" ") + "...";
    }
    return text;
  }
  function TypographyDemo(props: { loading?: boolean }) {
    const { loading = false } = props;

    const variants = [
      'h1',
      'h1',
      'h1',
      'h1',
    ] as readonly TypographyProps['variant'][];

    return (
      <div>
        {variants.map((variant) => (
          <Typography component="div" key={variant} variant={variant}>
            {loading ? <Skeleton /> : variant}
          </Typography>
        ))}
      </div>
    );
  }
  const handleDataLoaded = () => {
    setIsLoading(false);
  };
  setTimeout(handleDataLoaded, 500);
  const CustomButton = styled(Button)`
    background-color: blue;
    color: white;

    &:hover {
      background-color: #1565c0;
    }
  `;
  console.log("Block: ", block);
  return (
    <>
      <AdminHeader
        saveAction={() => {
          submitHandler(
            JSON.stringify({
              data: blocks,
            })
          );
        }}
        openPreview={handleOpenPreview}
      />
      <Container style={{ padding: "0.5rem 5rem 2rem 5rem" }}>
      {isLoading ? (
        <div>
          <TypographyDemo loading />
          <TypographyDemo loading />
          <TypographyDemo loading />
        </div>
      ) : (
        <>

        {blocks.map((b: IBlocks, index: number) => {
          return (
            <div key={`${b.index}-${index}`}>
              {b.type === "text" && (
                <>
                  <Action
                    index={index}
                    onRichTextClick={() => onRichTextClick(index)}
                    block={b}
                    setBlocks={setBlocks}
                    handleBlockLevelSave={() =>
                      handleBlockLevelSave(block, index)
                    }
                    setOpenImageUpload={setOpenImageUpload}
                    setOpenVideoUpload={setOpenVideoUpload}
                    setOpenDocumentUpload={setOpenDocumentUpload}
                    blocks={blocks}
                    setInsertIndex={() => setInsertIndex(index)}
                    setOpenEmbed={setOpenEmbed}
                    setOpenPreviewBlock={setOpenPreviewBlock}
                    setCurrentBlock={setCurrentBlock}
                    setIsEdit={setIsEdit}
                    handleClearStates={handleClearStates}
                  />

                  {(isEdit && block?.index === b.index) ||
                  (isAdd && block?.index === b.index) ? (
                    <>
                      <div className="text-type-div">
                        <div>
                          <br />
                          <br />
                          <TextField
                            id="outlined-required"
                            label="Section Header"
                            defaultValue={isEdit ? block?.header : ""}
                            fullWidth
                            onChange={handleSectionHeaderChange}
                          />
                        </div>
                        <br />
                        <div>
                          <TextField
                            id="outlined-required"
                            label="Article Title"
                            defaultValue={isEdit ? block?.title : ""}
                            fullWidth
                            onChange={handleBlockTitleChange}
                          />
                        </div>
                        <br />
                        <RichTextEditor
                          data={b.data}
                          setBlocks={setBlocks}
                          index={b.index}
                          blocks={blocks}
                        />
                        <br />
                        <div>
                          <label htmlFor="description">Description</label>
                          <textarea
                            name="description"
                            id="ikd"
                            style={{ width: "100%" }}
                            cols={30}
                            rows={10}
                            defaultValue={isEdit ? block?.desc : ""}
                            onChange={handleDescriptionChange}
                          ></textarea>
                        </div>

                        <br />
                      </div>
                    </>
                  ) : (
                    <div className="pdfBox">
                      <div>
                        <h3>{b.header ?? "Embed header"}</h3>
                        <strong>{b.title ?? "Doc title"}</strong>
                        <div className="pdfContent">{parse(b?.desc ?? "")}</div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {b.type === "image" && (
                <>
                  <Action
                    index={index}
                    onRichTextClick={() => onRichTextClick(index)}
                    block={b}
                    setBlocks={setBlocks}
                    handleBlockLevelSave={() =>
                      handleBlockLevelSave(block, index)
                    }
                    setOpenImageUpload={setOpenImageUpload}
                    setOpenVideoUpload={setOpenVideoUpload}
                    setOpenDocumentUpload={setOpenDocumentUpload}
                    handleClearStates={handleClearStates}
                    blocks={blocks}
                    setInsertIndex={() => setInsertIndex(index)}
                    setOpenEmbed={setOpenEmbed}
                    setOpenPreviewBlock={setOpenPreviewBlock}
                    setCurrentBlock={setCurrentBlock}
                    setIsEdit={setIsEdit}
                  />
                  <div className="pdfBox">
                    <div>
                      <h3>{b.header ?? "Embed header"}</h3>
                      <strong>{b.title ?? "Doc title"}</strong>
                      <div className="pdfContent">
                        {truncateText(b.desc, 8) ?? "Image Description"}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {b.type === "document" && (
                <>
                  <Action
                    index={index}
                    onRichTextClick={() => onRichTextClick(index)}
                    block={b}
                    setBlocks={setBlocks}
                    handleBlockLevelSave={() =>
                      handleBlockLevelSave(block, index)
                    }
                    handleClearStates={handleClearStates}
                    setOpenImageUpload={setOpenImageUpload}
                    setOpenVideoUpload={setOpenVideoUpload}
                    setOpenDocumentUpload={setOpenDocumentUpload}
                    blocks={blocks}
                    setInsertIndex={() => setInsertIndex(index)}
                    setOpenEmbed={setOpenEmbed}
                    setOpenPreviewBlock={setOpenPreviewBlock}
                    setCurrentBlock={setCurrentBlock}
                    setIsEdit={setIsEdit}
                  />
                  <div className="pdfBox">
                    <div>
                      <h3>{b.header ?? "Embed header"}</h3>
                      <strong>{b.title ?? "Doc title"}</strong>
                      <div className="pdfContent">
                        {truncateText(b.desc, 8) ?? "PDF Description"}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {b.type === "embed" && (
                <>
                  <Action
                    index={index}
                    onRichTextClick={() => onRichTextClick(index)}
                    block={b}
                    setBlocks={setBlocks}
                    setOpenImageUpload={setOpenImageUpload}
                    setOpenVideoUpload={setOpenVideoUpload}
                    handleBlockLevelSave={() =>
                      handleBlockLevelSave(block, index)
                    }
                    handleClearStates={handleClearStates}
                    setOpenDocumentUpload={setOpenDocumentUpload}
                    blocks={blocks}
                    setInsertIndex={() => setInsertIndex(index)}
                    setOpenEmbed={setOpenEmbed}
                    setOpenPreviewBlock={setOpenPreviewBlock}
                    setCurrentBlock={setCurrentBlock}
                    setIsEdit={setIsEdit}
                  />
                  <div className="pdfBox">
                    <div>
                      <h3>{b.header ?? "Embed header"}</h3>
                      <strong>{b.title ?? "Doc title"}</strong>
                      <div className="pdfContent">
                        {truncateText(b?.desc, 8) ?? "EmbedDescription"}
                      </div>
                    </div>
                    {/* <div className="pdfButton">
                      <a className="viewPdf" href={b.data} target="_blank">
                        View Embed Object
                      </a>
                    </div> */}
                  </div>
                </>
              )}
              {b.type === "video" && (
                <>
                  <Action
                    index={index}
                    onRichTextClick={() => onRichTextClick(index)}
                    block={b}
                    setBlocks={setBlocks}
                    setOpenImageUpload={setOpenImageUpload}
                    setOpenVideoUpload={setOpenVideoUpload}
                    handleBlockLevelSave={() =>
                      handleBlockLevelSave(block, index)
                    }
                    handleClearStates={handleClearStates}
                    setOpenDocumentUpload={setOpenDocumentUpload}
                    blocks={blocks}
                    setInsertIndex={() => setInsertIndex(index)}
                    setOpenEmbed={setOpenEmbed}
                    setOpenPreviewBlock={setOpenPreviewBlock}
                    setCurrentBlock={setCurrentBlock}
                    setIsEdit={setIsEdit}
                  />
                  <div className="pdfBox">
                    <div>
                      <h3>{b.header}</h3>
                      <strong>{b.title ?? "Doc Header"}</strong>
                      <div className="pdfContent">
                        {truncateText(b.desc, 8) ?? "Video Description..."}
                      </div>
                    </div>
                    {/* <div className="pdfButton">
                      <a className="viewPdf" href={b.data} target="_blank">
                        View Video
                      </a>
                    </div> */}
                  </div>
                </>
              )}
            </div>
          );
        })}
        <div className="btn-section">
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            +
          </Button>
          <Popover
            id={ids}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="popper-div">
              <p className="popper-title">Content Type</p>
              <div className="popper-child" onClick={() => onRichTextClick()}>
                <div className="popper-parent">
                  <div style={{ marginRight: "8px" }}>
                    <FormatIndentDecrease>image</FormatIndentDecrease>
                  </div>
                  <span className="popper-text">Rich Text</span>
                </div>
              </div>
              <div
                className="popper-child"
                onClick={() => setOpenDocumentUpload(true)}
              >
                <div className="popper-parent">
                  <div style={{ marginRight: "8px" }}>
                    <PictureAsPdfOutlined>image</PictureAsPdfOutlined>
                  </div>
                  <span className="popper-text">Document</span>
                </div>
              </div>
              <div
                className="popper-child"
                onClick={() => setOpenVideoUpload(true)}
              >
                <div className="popper-parent">
                  <div style={{ marginRight: "8px" }}>
                    <SmartDisplayOutlined>image</SmartDisplayOutlined>
                  </div>
                  <span className="popper-text"> Video</span>
                </div>
              </div>
              <div
                className="popper-child"
                onClick={() => setOpenImageUpload(true)}
              >
                <div className="popper-parent">
                  <div style={{ marginRight: "8px" }}>
                    <ImageOutlined>image</ImageOutlined>
                  </div>
                  <span className="popper-text">Image</span>
                </div>
              </div>
              <div className="popper-child" onClick={() => setOpenEmbed(true)}>
                <div className="popper-parent">
                  <div style={{ marginRight: "8px" }}>
                    <CodeOffOutlined>image</CodeOffOutlined>
                  </div>
                  <span className="popper-text">Embed</span>
                </div>
              </div>
            </div>
          </Popover>

          <br />
          <br />
          {/* <Button
            variant="contained"
            onClick={() =>
              submitHandler(
                JSON.stringify({
                  data: blocks,
                })
              )
            }
          >
            Save
          </Button> */}

          <FormDialog
            title="Choose Image"
            show={openImageUpload}
            setShow={setOpenImageUpload}
          >
            <div>
              <TextField
                id="outlined-required"
                label="Section Header"
                defaultValue={isEdit ? block?.header : ""}
                fullWidth
                onChange={handleSectionHeaderChange}
              />
            </div>
            <br />
            <div>
              <TextField
                id="outlined-required"
                label="Image Title"
                defaultValue={isEdit ? block?.title : ""}
                fullWidth
                onChange={handleBlockTitleChange}
              />
            </div>
            <div className="form-btn">
              <Button onClick={handleImageAdd}>
                Choose Image
                <input
                  ref={imageRef}
                  onChange={onImageUpload}
                  type="file"
                  accept={"image/*"}
                  style={{ display: "none" }}
                  placeholder="select image"
                />
              </Button>
            </div>
            <div>
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="ikd"
                style={{ width: "100%" }}
                cols={30}
                rows={10}
                defaultValue={isEdit ? block?.desc : ""}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
            <div className="form-action">
              <Button
                onClick={() => {
                  setOpenImageUpload(false);
                  setFile(undefined);
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => onFileSubmitHandler("image", insertIndex)}>
                Save
              </Button>
            </div>
          </FormDialog>

          <FormDialog
            title="Choose Document"
            show={openDocumentUpload}
            setShow={setOpenDocumentUpload}
          >
            <div>
              <TextField
                id="outlined-required"
                label="Section Header"
                defaultValue={isEdit ? block?.header : ""}
                fullWidth
                onChange={handleSectionHeaderChange}
              />
            </div>
            <br />

            <div>
              <TextField
                id="outlined-required"
                label="Document Title"
                defaultValue={isEdit ? block?.title : ""}
                fullWidth
                onChange={handleBlockTitleChange}
              />
            </div>
            <br/>
            <div className="form-btn">
              <br />
              <Button onClick={handleDocumentAdd}>
                Choose Document
                <input
                  ref={documentRef}
                  onChange={onDocumentUpload}
                  type="file"
                  accept={"pdf/*"}
                  style={{ display: "none" }}
                  placeholder="select pdf"
                />
              </Button>
            </div>
            <div className="description-info">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="ikd"
                style={{ width: "100%" }}
                cols={30}
                rows={10}
                defaultValue={isEdit ? block?.desc : ""}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
            <div className="form-action">
              <CustomButton
                onClick={() => {
                  setOpenDocumentUpload(false);
                  setFile(undefined);
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={() => onFileSubmitHandler("document", insertIndex)}
              >
                Save
              </CustomButton>
            </div>
          </FormDialog>
          <FormDialog
            title="Choose Video"
            show={openVideoUpload}
            setShow={setOpenVideoUpload}
          >
            <div>
              <TextField
                id="outlined-required"
                label="Section Header"
                defaultValue={isEdit ? block?.header : ""}
                fullWidth
                onChange={handleSectionHeaderChange}
              />
            </div>
            <br />
            <div>
              <TextField
                id="outlined-required"
                label="Video Title"
                defaultValue={isEdit ? block?.title : ""}
                fullWidth
                onChange={handleBlockTitleChange}
              />
            </div>
            <br />
            <div className="form-btn">
              <Button onClick={handleVideoAdd}>
                Choose Video
                <input
                  ref={videoRef}
                  onChange={onVideoUpload}
                  type="file"
                  accept={"video/*"}
                  style={{ display: "none" }}
                  placeholder="select image"
                />
              </Button>
            </div>
            <div className="description-info">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="ikd"
                style={{ width: "100%" }}
                cols={30}
                rows={10}
                defaultValue={isEdit ? block?.desc : ""}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>

            <div className="form-action">
              <CustomButton
                onClick={() => {
                  setOpenVideoUpload(false);
                  setFile(undefined);
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={() => onFileSubmitHandler("video", insertIndex)}
              >
                Save
              </CustomButton>
            </div>
          </FormDialog>

          <FormDialog title="IFrame " show={openEmbed} setShow={setOpenEmbed}>
            <div>
              <TextField
                id="outlined-required"
                label="Section Header"
                defaultValue={isEdit ? block?.header : ""}
                fullWidth
                onChange={handleSectionHeaderChange}
              />
            </div>
            <br />
            <div>
              <TextField
                id="outlined-required"
                label="Title"
                defaultValue={isEdit ? block?.title : ""}
                fullWidth
                onChange={handleBlockTitleChange}
              />
            </div>
            <div className="" style={{ width: "100%", padding: "1rem 0" }}>
              <label htmlFor="iframe">Insert Iframe</label>
              <textarea
                name="iframe"
                id="ikd"
                style={{ width: "100%" }}
                cols={30}
                rows={10}
                ref={embedRef}
                defaultValue={isEdit ? block?.data : ""}
              ></textarea>
            </div>
            <div>
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="ikd"
                style={{ width: "100%" }}
                cols={30}
                rows={10}
                defaultValue={isEdit ? block?.desc : ""}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
            <div className="form-action">
              <Button
                onClick={() => {
                  setOpenEmbed(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => handleEmbedAdd(insertIndex)}>Save</Button>
            </div>
          </FormDialog>

          <FormDialog
            title="Preview Block "
            show={openPreviewBlock}
            setShow={setOpenPreviewBlock}
          >
            {block && <CustomParser block={block} />}
          </FormDialog>
        </div>
        </>
        )}
      </Container>
    </>
  );
};

export default CustomEditor;
