import Http from "./Http";

const http = new Http();

export default class ppsfService {
  static getPPSF() {
    return http.get<any>({
      endpoint: `/heart/ppsf/`,
    });
  }
  static postPPSF(args: any) {
    return http.post<any>({
      endpoint: `/heart/ppsf/`,
      payload: args,
    });
  }
  static patchPPSF(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/ppsf/${id}/`,
      payload: args,
    });
  }
}
