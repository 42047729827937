import React from "react";
import BoDPersonnelService from "../../../common/services/bodPersonel";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

interface BoDPersonnelFormProps {
  onSuccess: () => void;
  onClose: () => void; // Add a new prop to handle closing the modal
}

const BoDPersonnelForm = ({ onSuccess, onClose }: BoDPersonnelFormProps) => {
  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      name: data.name,
    };

    BoDPersonnelService.postPersonnel(payload)
      .then((response: any) => {
        toast.success("Personnel created successfully!");
        onSuccess(); // Call the onSuccess callback to refresh the table
        onClose();   // Call the onClose callback to close the modal
      })
      .catch((error: any) => {
        toast.error("Failed to create personnel!");
      });
  };

  const formFields: Field[] = [
    {
      id: "name",
      type: "text" as const,
      label: "Name",
      required: true,
    },
  ];

  return (
    <>
      <div>
        <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default BoDPersonnelForm;
