import Http from "./Http";

const http = new Http();

export default class x5cadService {
  static getx5cad() {
    return http.get<any>({
      endpoint: `/heart/x5cad/`,
    });
  }
  static postx5cad(args: any) {
    return http.post<any>({
      endpoint: `/heart/x5cad/`,
      payload: args,
    });
  }
  static patchx5cad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/x5cad/${id}/`,
      payload: args,
    });
  }
}
