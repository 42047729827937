import React, { useEffect } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import "video.js/dist/video-js.css";
import { getToken } from "../../utils/token";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  useEffect(() => {
    videojs.Vhs.xhr.beforeRequest = (options) => {
      const headers = options.headers || {};
      const token = getToken({
        name: "token",
      });
      headers.Authorization = "Token " + token;
      options.headers = headers;
      return options;
    };
  }, [options]);
  React.useEffect(() => {
    if (!playerRef.current) {
      const player = (playerRef.current = videojs(
        videoRef.current,
        options,
        () => {
          videojs.log("player is ready");
          onReady && onReady(player);
        }
      ));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, onReady]);

  return (
    <video
      ref={videoRef}
      className="video-js vjs-default-skin vjs-big-play-centered"
    />
  );
};

export default VideoJS;
