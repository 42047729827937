import Http from "./Http";

const http = new Http();

export default class DurationService {
  // Get all durations
  static getDurations() {
    return http.get<any>({
      endpoint: `/duration/`,
    });
  }

  // Post a new duration
  static postDuration(args: any) {
    return http.post<any>({
      endpoint: `/duration/`,
      payload: args,
    });
  }

  // Get a specific duration by UUID
  static getDuration(uuid: string) {
    return http.get<any>({
      endpoint: `/duration/${uuid}/`,
    });
  }

  // Delete a specific duration by UUID
  static deleteDuration(uuid: string) {
    return http.delete<any>({
      endpoint: `/duration/${uuid}/`,
    });
  }
}
