import Http from "./Http";

const http = new Http();

export default class TuitionService {
  // GET: /tuition/
  static getTuition() {
    return http.get<any>({
      endpoint: `/tuition/`,
    });
  }

  // POST: /tuition/
  static postTuition(args: any) {
    return http.post<any>({
      endpoint: `/tuition/`,
      payload: args,
    });
  }

  // GET: /tuition/{uuid}/
  static getTuitionById(uuid: string) {
    return http.get<any>({
      endpoint: `/tuition/${uuid}/`,
    });
  }

  // DELETE: /tuition/{uuid}/
  static deleteTuition(uuid: string) {
    return http.delete<any>({
      endpoint: `/tuition/${uuid}/`,
    });
  }
}
