import React from "react";
import DurationService from "../../../common/services/DurationService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

interface DurationFormProps {
  onAddSuccess: () => void;
}

const DurationForm = ({ onAddSuccess }: DurationFormProps) => {
  const handleSubmit = (data: { [key: string]: any }) => {
    DurationService.postDuration(data)
      .then(() => {
        toast.success("Saved successfully!");
        onAddSuccess(); // Call the callback to refresh data
      })
      .catch(() => {
        toast.error("Failed to save!");
      });
  };

  const formFields: Field[] = [
    {
      id: "lesson_length",
      type: "text",
      label: "Lesson Length",
      required: true,
    },
  ];

  return (
    <div>
      <ReusableForm fields={formFields} onSubmit={handleSubmit} />
    </div>
  );
};

export default DurationForm;
