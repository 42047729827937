import Http from "./Http";

const http = new Http();

interface IUserCreate {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  group?: string;
  custom_group?: string;
}

interface ILoginResponse {
  token: string;
  available_tokens: number;
  group: string;
}

interface ILoginInput {
  username: string;
  password: string;
}
export default class Auth {
  static login(args: ILoginInput) {
    return http.post<ILoginResponse>({
      endpoint: `api-token-auth/`,
      payload: args,
    });
  }
  static createNormalUser(args: IUserCreate) {
    return http.post<any>({
      endpoint: `/account/register/`,
      payload: args,
    });
  }
  static patchUser(args: IUserCreate, username: string) {
    return http.patch<any>({
      endpoint: `/account/${username}/`,
      payload: args,
    });
  }

  static getUser(username: string) {
    return http.get<any>({
      endpoint: `/account/${username}/`,
    });
  }
  static getPartners(username: string) {
    return http.get<any>({
      endpoint: `/account/partners/`,
    });
  }
  static getUserList(username: string) {
    return http.get<any>({
      endpoint: `/account/`,
    });
  }
  static postPartners(args: any) {
    return http.post<any>({
      endpoint: `/account/partners/`,
      payload: args,
    });
  }
  static postUser(args: any) {
    return http.post<any>({
      endpoint: `account/user/special_onboard/`,
      payload: args,
    });
  }
  static retrieveUser(username: string) {
    return http.get<any>({
      endpoint: `/users/${username}/profile`,
    });
  }
}
