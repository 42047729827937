import React, { useState, useEffect } from "react";
import bodTagService from "../../../common/services/bodTagService";
import bodPersonnel from "../../../common/services/bodPersonel";
import bodDesignation from "../../../common/services/bodDesignation";
import bodTagMemberService from "../../../common/services/bodTagMemberService";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

interface BoDTagMemberFormProps {
  onSuccess?: () => void;
}

const BoDTagMemberForm: React.FC<BoDTagMemberFormProps> = ({ onSuccess }) => {
  const [tags, setTags] = useState<any[]>([]);
  const [personnel, setPersonnel] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const tagsResponse = await bodTagService.getTags();
        const personnelResponse = await bodPersonnel.getPersonnel();
        const designationsResponse = await bodDesignation.getDesignations();

        setTags(tagsResponse || []);
        setPersonnel(personnelResponse || []);
        setDesignations(designationsResponse || []);
      } catch (error) {
        toast.error("Failed to fetch options!");
      }
    };

    fetchOptions();
  }, []);

  const handleSubmit = async (data: { [key: string]: any }) => {
    const payload = {
        tag: data.tag,
        personnel: data.personnel,
        designation: data.designation,
      };

    try {
      await bodTagMemberService.postMember(payload);
      toast.success("BoD Tag Member saved successfully!");
      if (onSuccess) onSuccess(); // Call the callback function on success
    } catch (error) {
      toast.error("Failed to save BoD Tag Member!");
    }
  };

  const formFields: Field[] = [
    {
      id: "tag",
      type: "select" as const,
      label: "Tag",
      options: tags.length ? tags.map(tag => ({ value: tag.uuid, label: tag.tag_name })) : [],
      required: true,
    },
    {
      id: "personnel",
      type: "select" as const,
      label: "Personnel",
      options: personnel.length ? personnel.map(person => ({ value: person.uuid, label: person.name })) : [],
      required: true,
    },
    {
      id: "designation",
      type: "select" as const,
      label: "Designation",
      options: designations.length ? designations.map(designation => ({ value: designation.uuid, label: designation.designation })) : [],
      required: true,
    },
  ];

  return (
    <div>
      <ReusableForm fields={formFields} onSubmit={handleSubmit} />
    </div>
  );
};

export default BoDTagMemberForm;
