import React from "react";
import BoDService from "../../../common/services/bodDesignation";
import { toast } from "react-toastify";
import ReusableForm from "../FormComponent/ResuableForm";
import { Field } from "../FormComponent/types";

interface BoDDesignationProps {
  onAddSuccess: () => void;
}

const BoDDesignation = ({ onAddSuccess }: BoDDesignationProps) => {
  const handleSubmit = (data: { [key: string]: any }) => {
    const payload = {
      designation: data.designation,
    };

    BoDService.postDesignation(payload)
      .then((response: any) => {
        toast.success("Saved successfully!");
        onAddSuccess(); // Call the callback to refresh data
      })
      .catch((error: any) => {
        toast.error("Failed to save!");
      });
  };

  const formFields: Field[] = [
    {
      id: "designation",
      type: "text" as const,
      label: "Designation",
      required: true,
    },
  ];

  return (
    <>
      <div>
          <ReusableForm fields={formFields} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default BoDDesignation;
