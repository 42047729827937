import React, { useEffect, useState } from "react";
import piorService from "../../../common/services/Pior";
import Preview from "../../../common/components/Dialog/Preview";
import CustomEditor from "../../../common/components/CustomEditor";
import { toast } from "react-toastify";

const PIOR = () => {
  const [ppoData, setPpoData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!ppoData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      piorService.patchPIOR(payload, id)
        .then((response: any) => {
          console.log(response);
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          console.log(error);
        });
    } else {
      piorService.postPIOR(payload)
        .then((response: any) => {
          console.log(response);
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    piorService.getPIOR()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setPpoData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <div style={{ marginTop: "3.5rem" }}>
      <CustomEditor submitHandler={handleSubmit} data={ppoData} handleOpenPreview={handleOpenPreview} />
      <Preview title="Packsize Installation and Operation Resources" open={openPreview} onClose={handleClosePreview} blocks={ppoData} />
    </div>
  );
};

export default PIOR;
