import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomEditor from "../../../common/components/CustomEditor";
import Preview from "../../../common/components/Dialog/Preview";
import PpoService from "../../../common/services/Ppo";

const PPO = () => {
  const [ppoData, setPpoData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!ppoData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      PpoService.patchPPO(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!");
        })
        .then((error: any) => {
          console.log(error);
        });
    } else {
      PpoService.postPPO(payload)
        .then((response: any) => {
          toast.success("Saved successfully!");
          console.log(response);
        })
        .then((error: any) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    PpoService.getPPO()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setPpoData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div style={{ marginTop: "3.5rem" }}>
        <CustomEditor submitHandler={handleSubmit} data={ppoData} handleOpenPreview={handleOpenPreview} />
        <Preview title="Packsize & Portal Overview" open={openPreview} onClose={handleClosePreview} blocks={ppoData} />
      </div>
    </>
  );
};

export default PPO;
