import React, { useState, useEffect } from "react";
import DurationService from "../../../common/services/DurationService";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import DurationForm from "./DurationForm";
import DurationTuitionForm from "../DurationTuition/DurationTuitionForm";

const columns: Column[] = [
  { id: "lesson_length", label: "Length", minWidth: 170, align: "left" },
];

const DurationView = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDurationModal, setOpenDurationModal] = useState(false);
  const [openDurationTuitionModal, setOpenDurationTuitionModal] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await DurationService.getDurations();
      setRows(response);
    } catch (error) {
      console.error("Error fetching durations:", error);
    }
  };

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseDurationModal = () => {
    setOpenDurationModal(false);
  };

  const handleCloseDurationTuitionModal = () => {
    setOpenDurationTuitionModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.uuid === editData.uuid ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.uuid !== rowToDelete.uuid));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleDurationAddSuccess = () => {
    fetchData();
    handleCloseDurationModal();
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Duration</div>
          <div>
            <Button 
              onClick={() => setOpenDurationModal(true)}
              style={{ marginRight: '16px' }}
              className={style.openModel}
            >
              Add Duration
            </Button>
            <Button 
              onClick={() => setOpenDurationTuitionModal(true)}
              className={style.openModel}
            >
              Add Duration Tuition
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Row</DialogTitle>
          <DialogContent>
            <TextField
              label="Lesson Length"
              name="lesson_length"
              fullWidth
              margin="normal"
              value={editData?.lesson_length || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this row?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="secondary" className={style.modelButton}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDurationModal}
          onClose={handleCloseDurationModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Add Duration
            <IconButton
              aria-label="close"
              onClick={handleCloseDurationModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DurationForm onAddSuccess={handleDurationAddSuccess} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={openDurationTuitionModal}
          onClose={handleCloseDurationTuitionModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Add Duration Tuition
            <IconButton
              aria-label="close"
              onClick={handleCloseDurationTuitionModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DurationTuitionForm />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default DurationView;
