import { Button, Menu, styled } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { IBlocks } from ".";
import {
  CodeOffOutlined,
  FormatIndentDecrease,
  ImageOutlined,
  SmartDisplayOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";

const CustomButton = styled(Button)`
  color: #000;
`;

interface IProps {
  onRichTextClick: () => void;
  setOpenImageUpload: Dispatch<SetStateAction<boolean>>;
  setOpenVideoUpload: Dispatch<SetStateAction<boolean>>;
  setOpenDocumentUpload: Dispatch<SetStateAction<boolean>>;
  setOpenEmbed: Dispatch<SetStateAction<boolean>>;
  setAnchorEl2: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  handleClearStates: () => void;
}
const NestedAction = (props: IProps) => {
  const {
    onRichTextClick,
    setOpenImageUpload,
    setOpenVideoUpload,
    setOpenDocumentUpload,
    setAnchorEl2,
    setOpenEmbed,
    handleClearStates
  } = props;
  const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick3 = (event: any) => {
    setAnchorEl3(event.currentTarget);
    handleClearStates();
  };

  const handleClose = () => {
    setAnchorEl3(null);
  };

  useEffect(() => {
    return () => {
      setAnchorEl3(null);
    };
  }, []);

  return (
    <div className="popper-child">
      <CustomButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick3}
      >
        Add Section
      </CustomButton>
      <Menu
        id="simple-menu1234"
        anchorEl={anchorEl3}
        open={Boolean(anchorEl3)}
        onClose={handleClose}
      >
        <div className="popper-div-action">
          <p className="popper-title">Content Type</p>
          <div
            className="popper-child"
            onClick={() => {
              onRichTextClick();
              setAnchorEl3(null);
              setAnchorEl2(null);
            }}
          >
            <div className="popper-parent">
              <div style={{ marginRight: "8px" }}>
                <FormatIndentDecrease>image</FormatIndentDecrease>
              </div>
              <span className="popper-text">Rich Text</span>
            </div>
          </div>
          <div
            className="popper-child"
            onClick={() => {
              setOpenDocumentUpload(true);
              setAnchorEl3(null);
              setAnchorEl2(null);
            }}
          >
            <div className="popper-parent">
              <div style={{ marginRight: "8px" }}>
                <PictureAsPdfOutlined>image</PictureAsPdfOutlined>
              </div>
              <span className="popper-text">Document</span>
            </div>
          </div>
          <div
            className="popper-child"
            onClick={() => {
              setOpenVideoUpload(true);
              setAnchorEl3(null);
            }}
          >
            <div className="popper-parent">
              <div style={{ marginRight: "8px" }}>
                <SmartDisplayOutlined>image</SmartDisplayOutlined>
              </div>
              <span className="popper-text"> Video</span>
            </div>
          </div>

          <div
            className="popper-child"
            onClick={() => {
              setOpenImageUpload(true);
              setAnchorEl3(null);
              setAnchorEl2(null);
            }}
          >
            <div className="popper-parent">
              <div style={{ marginRight: "8px" }}>
                <ImageOutlined>image</ImageOutlined>
              </div>
              <span className="popper-text">Image</span>
            </div>
          </div>
          <div
            className="popper-child"
            onClick={() => {
              setOpenEmbed(true);
              setAnchorEl3(null);
            }}
          >
            <div className="popper-parent">
              <div style={{ marginRight: "8px" }}>
                <CodeOffOutlined>image</CodeOffOutlined>
              </div>
              <span className="popper-text">Embed</span>
            </div>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default NestedAction;
