import React from "react";
import { useNavigate } from "react-router-dom";
import X5 from "../../../assets/machineLogo/X5CM.png";
import X7 from "../../../assets/machineLogo/X7CM.png";
import x4 from "../../../assets/machineLogo/X4CM.png";
import em7 from "../../../assets/machineLogo/EM7CM.png";
import iqf from "../../../assets/machineLogo/IQFCM.png";
import AGCM from "../../../assets/machineLogo/AGCM.png";
import "./style.css";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";

const CADS = () => {
  const navigate = useNavigate();

  const handlePacksizeCADDetails = (page: any) => {
    navigate("/admin/cads" + page);
  };

  const packsizeCardCAD = [
    {
      image: `${X5}`,
      alt: "X5CM",
      title: "X5 CAD Models",
      page: "/x5/",
    },
    {
      image: `${x4}`,
      alt: "X4CM",
      title: "X4 CAD Models",
      page: "/x4/",
    },
    {
      image: `${X7}`,
      alt: "X7CM",
      title: "X7 CAD Models",
      page: "/x7/",
    },
    {
      image: `${em7}`,
      alt: "EM7",
      title: "EM7 CAD Models",
      page: "/em7/",
    },
    {
      image: `${iqf}`,
      alt: "IQF",
      title: "iQ Fusion CAD Models",
      page: "/iqf/",
    },
    {
      image: `${AGCM}`,
      alt: "AGCM",
      title: "AutoGluer CAD Models",
      page: "/agcm/",
    },
  ];
  return (
    <>
      <AdminHeader title="CADs" />
      <div className="CADFiles">
        <h3>CAD Files</h3>
        <div className="CADFilesChild">
          {packsizeCardCAD.map((cardCAD, index) => (
            <div
              className="CADFilesCard"
              key={index}
              onClick={() => handlePacksizeCADDetails(cardCAD.page)}
            >
              <img src={cardCAD.image} alt={cardCAD.alt} />
              <span className="CADFileTitle">{cardCAD.title}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CADS;
