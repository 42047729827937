import Http from "./Http";

const http = new Http();

export default class bodTagService {
  // GET: /bod-tag/
  static getTags() {
    return http.get<any>({
      endpoint: `/bod-tag/`,
    });
  }

  // POST: /bod-tag/
  static postTag(args: any) {
    return http.post<any>({
      endpoint: `/bod-tag/`,
      payload: args,
    });
  }

  // GET: /bod-tag/{uuid}/
  static getTagById(uuid: string) {
    return http.get<any>({
      endpoint: `/bod-tag/${uuid}/`,
    });
  }

  // DELETE: /bod-tag/{uuid}/
  static deleteTag(uuid: string) {
    return http.delete<any>({
      endpoint: `/bod-tag/${uuid}/`,
    });
  }
}
