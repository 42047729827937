import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomEditor from "../../../common/components/CustomEditor";
import Preview from "../../../common/components/Dialog/Preview";
import pbcrService from "../../../common/services/Pbcr";

const PBCR = () => {
  const [pbcrData, setpbcrData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!pbcrData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }

  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      pbcrService.patchPBCR(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .then((error: any) => {

          toast.error('Failed to save!');
        });
    } else {
      pbcrService.postPBCR(payload)
        .then((response: any) => {
          toast.success("Saved successfully!")
        })
        .then((error: any) => {
          toast.error('Failed to save!')
        });
    }
  };

  useEffect(() => {
    pbcrService.getPBCR()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setpbcrData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        toast.error('Failed to laod!')
      });
  }, []);

  return (
    <div style={{ marginTop: "3.5rem" }}>
      <CustomEditor submitHandler={handleSubmit} data={pbcrData} handleOpenPreview={handleOpenPreview} />
      <Preview title="Packsize Qualification & Business Case Resources" open={openPreview} onClose={handleClosePreview} blocks={pbcrData} />
    </div>
  );
};

export default PBCR;
