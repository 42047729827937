import Http from "./Http";

const http = new Http();

export default class pcsService {
  static getPCS() {
    return http.get<any>({
      endpoint: `/heart/pcs/`,
    });
  }
  static postPCS(args: any) {
    return http.post<any>({
      endpoint: `/heart/pcs/`,
      payload: args,
    });
  }
  static patchPCS(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/pcs/${id}/`,
      payload: args,
    });
  }
}
