import React, { useState, useEffect } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import BoDTagMemberForm from "./BoDTagMemberForm";
import bodTagMemberService from "../../../common/services/bodTagMemberService";

const columns: Column[] = [
  { id: "personnelName", label: "Personnel Name", minWidth: 150, align: "left" },
  { id: "designationName", label: "Designation", minWidth: 150, align: "left" },
  { id: "tagName", label: "Tag", minWidth: 150, align: "left" },
];

const BoDTagMemberView = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log("Fetching data from API...");
      const tagMemberResponse = await bodTagMemberService.getMembers();

      console.log("Raw API Response:", tagMemberResponse);

      const formattedRows = tagMemberResponse.map((member: any) => ({
        id: member.uuid,
        personnelName: member.personnel?.name || "N/A",
        designationName: member.designation?.designation || "N/A",
        tagName: member.tag?.tag_name || "N/A",
      }));

      console.log("Formatted Rows:", formattedRows);

      setRows(formattedRows);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClosePartnerModal = () => {
    setOpenPartnerModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === editData.id ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleAddTagMemberSuccess = () => {
    fetchData(); // Refresh data on successful addition
    setOpenPartnerModal(false); // Close the add dialog
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>Tag Member</div>
          <div>
            <Button onClick={() => setOpenPartnerModal(true)} className={style.openModel}>
              Add Tag Member
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable columns={columns} rows={rows} onEdit={handleEdit} onDelete={handleDelete} />
        </div>

        {/* Dialogs for Edit, Delete, and Add */}
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Tag Member</DialogTitle>
          <DialogContent>
            {/* Form fields for editing */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this Tag Member?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary" className={style.modelButton}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPartnerModal}
          onClose={handleClosePartnerModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            Add TagMember
            <IconButton
              aria-label="close"
              onClick={handleClosePartnerModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BoDTagMemberForm onSuccess={handleAddTagMemberSuccess} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default BoDTagMemberView;
