import NavBar from "../../../common/components/NavBar";
import style from "./style.module.scss";
import Footer from "../../../common/components/Footer";
import React, { useEffect, useState } from "react";
import PpoService from "../../../common/services/Ppo";
import Output from "editorjs-react-renderer";
import { Container } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf"; // Import react-pdf components'
import OutputParse from "../../../common/components/OutputParse";
import CustomParser from "../../../common/components/CustomEditor/CustomParser";
import { IBlocks } from "../../../common/components/CustomEditor";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PacksizePortalOverview = () => {
  const [ppoData, setPpoData] = useState<IBlocks[]>([]);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  // Function to render individual pages
  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <div key={i} className="pdf-page" >
          <Page
            pageNumber={i}
            width={600} // Adjust the width as needed
          />
        </div>
      );
    }
    return pages;
  };
  useEffect(() => {
    PpoService.getPPO()
      .then((res: any) => {
        const dataString = res[0]?.htmltext;
        setPpoData(JSON.parse(dataString).data)
        // console.log(ppoData, "this is ppoData ")
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);
  // Define interfaces for your data
  // console.log(ppoData, "this is ppoData ")

  const FileRenderer = (data: any) => {
    if (data) {
      return (
        <>
          <a
            href={data?.data?.file?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.data?.file?.name}
          </a>
          <br />
        </>
      );
    }
  };

  const VideoRenderer = (
    data: any,
    style: React.CSSProperties,
    classNames: "string"
  ) => {
    if (data) {
      return (
        <>
          <video
            src={data?.data?.file?.url}
            controls
            style={{
              width: "70%",
              marginTop: "1rem",
            }}
            className={classNames}
          >
            Your browser does not support the video tag.
          </video>
          <br />
        </>
      );
    }
  };
  const renderers = {
    // Include the "attaches" type renderer
    attaches: FileRenderer,
    video: VideoRenderer,
    // Add your other custom renderers here
  };
  return (
    <>
      <NavBar />
      <Container style={{paddingBottom: '30px' }}>
        {
          ppoData && ppoData.map((block) => {
            return <CustomParser block={block} />
          })
        }
      </Container>
    </>
  );
};
export default PacksizePortalOverview;
