export const HOME = '/home';
export const LOGIN = '/';
export const DASHBOARD = '/dash';
export const PPO = '/ppo/';
export const PPSS = '/ppss/';
export const PPOTV = '/ppotv/';
export const PPSRG = '/ppsrg/';
export const PSSE = '/psse/';
export const PCS = '/pcs/';
export const PIOR = '/pior/';
export const PBCR = '/pbcr';
export const PAMC = '/pamc';
export const PPSF ='/ppsf/';
export const X5CM = '/x5cm/';
export const X4CM = '/x4cm/';
export const X7CM = '/x7cm/';
export const EM7CM = '/em7cm/';
export const IQFCM = '/iqfcm/';
export const AGCM = '/agcm/';
export const BODDESIGNATION = '/bod-designation/';
export const BODPERSONAL = '/bod-personal/';
export const BODTAG = '/bod-tag/';
export const DURATION = '/duration/';
export const TUTION = '/tution/';
export const DURATIONTUTION = '/duration-tution/';
export const EVENT = '/duration-tution/';
export const PROJECTGRIT = '/project-grit/';
export const GRITPARTNER = '/grit-partner/';
export const TAGMEMBER = '/bod-tag-member/';











