import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import packsizelogo from "../../../assets/PacksizeLogo/packsize_no_tagline_3C.svg";
import { useNavigate } from "react-router-dom";
import auth from "../../../config/auth";
import pLogo from "../../../assets/UserIcon/Guser.svg";

const NavBar = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const isAuthed = auth.isAuthenticated();
  const [isNavLinkVisible, setIsNavLinkVisible] = useState(false);

  const handleLogin = () => {
    if (isAuthed) {
      localStorage.removeItem("token");
    }
    navigate("/login");
  };
  const handleGoToHome = () => {
    navigate("/");
  };

  const handleOpenPacksizeOverview = () => {
    isAuthed ? navigate("/ppo") : navigate("/login")
  };

  const handleOpenDropdown = (e: any) => {
    e.stopPropagation(); // Prevent event propagation
    toggleDropdown();
  };

  const toggleHamburger = (e: any) => {
    e.stopPropagation();
    setIsNavLinkVisible(!isNavLinkVisible);
  }
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);

  };
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (isDropdownOpen && !e.target.closest(".dropdown")) {
        setIsDropdownOpen(false);
      }


      if (isNavLinkVisible && !e.target.closest(".hamburgerNavLinkParent") &&
        !e.target.closest(".hamburgerParent")) {
        setIsNavLinkVisible(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen, isNavLinkVisible]);
  console.log(isNavLinkVisible, "toggle?")


  return (
    <div className={style.navBar}>
      <div className={style.navBarChild}>
        <span onClick={() => handleGoToHome()}>
          <img src={packsizelogo} alt="packsizeLogo" className={style.packsizeLogo} />
        </span>
        <div className={style.navLink}>
          <span
            className={style.portalOverview}
            onClick={handleOpenPacksizeOverview}
          >
            Overview
          </span>
          <span>
            {isAuthed ? (
              <div className={style.dropdown}>
                <img
                  src={pLogo}
                  alt=""
                  className={style.user_img}
                  onClick={handleOpenDropdown}
                />
                {isDropdownOpen && (
                  <ul className={style.userDropdownitem}>

                    <li>My Profile</li>
                    <li
                      onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/login");
                      }}
                    >
                      Log Out
                    </li>
                  </ul>

                )}
              </div>
            ) : (
              <div className={style.partnetLogin} onClick={handleLogin}>
                Partner Login
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={style.hamburgerParent} onClick={toggleHamburger}>
        <div className={style.hamburgerBars}>
          <span className={style.hamburgerBar}></span>
          <span className={style.hamburgerBar}></span>
          <span className={style.hamburgerBar}></span>
        </div>
        {isNavLinkVisible && (
          <div className={style.hamburgerNavLinkParent}>
            <div className={style.hamburgerNavLink}>
              <span
                className={style.portalOverview}
                onClick={handleOpenPacksizeOverview}
              >
                Overview
              </span>
              {isAuthed ? (
                <>
                  <span>
                    My Profile
                  </span>
                  <span
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                    }}
                  >
                    Log Out
                  </span>
                </>
              ) : (
                <span onClick={handleLogin}>
                  Partner Login
                </span>
              )}
            </div>
          </div>
        )}

      </div>

    </div >
  );
};

export default NavBar;
