import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";

// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
// import Image from "@ckeditor/ckeditor5-image/src/image";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IBlocks } from "../CustomEditor";
import './styles.css';
interface IProps{
  data: string;
  setBlocks: Dispatch<SetStateAction<IBlocks[]>>;
  index: number;
  blocks: IBlocks[];
}

const RichTextEditor = (props:IProps) => {
  const [editorData, setEditorData] = useState<string>(
    "<p>Start typing here...</p>"
  );
    const {data, setBlocks, index, blocks} = props;
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const [value, setValue] = useState(data);
  const customToolbarOptions = [
    ["bold", "italic", "underline", "strike"], // Text formatting
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    ["link"], // Links
    ["table"], // Table
  ];
  useEffect(()=>{
    setBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];
      const changeIndex = updatedBlocks.findIndex(block => block.index === index)
      updatedBlocks[changeIndex].data = value;
      return updatedBlocks;
    });
  }, [value])
  return (
    <>
      <div>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{
            toolbar: customToolbarOptions,
          }}
        />
      </div>
    </>
  );
};

export default RichTextEditor;
