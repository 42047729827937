import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Auth from "../../../common/services/UserService";
import "./styles.css";
import { useNavigate } from "react-router-dom";

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  partner: string;
  password: string;
}

const AddUser = () => {
  const { handleSubmit, control } = useForm<FormData>();
  const [partners, setPartners] = useState([]);
  const navigate = useNavigate();

  const onSubmit = (data: FormData) => {
    Auth.postUser(data)
      .then((response) => {
        navigate("/admin/users");
        if (response.ok) {
          alert("User Added");
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error adding user:", error);
      });
  };

  useEffect(() => {
    Auth.getPartners("/api/v1/account/partners/")
      .then((response) => {
        setPartners(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Try to parse the response as JSON
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <h1> User Form</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              {...field}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              required
              {...field}
            />
          )}
        />
        <Controller
          name="partner"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "15px" }}
            >
              <InputLabel id="partner-label">Partner</InputLabel>
              <Select labelId="partner-label" label="Partner" {...field}>
                {partners.map((partner: any) => (
                  <MenuItem key={partner.uuid} value={partner.uuid}>
                    {partner.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <div className="addUser">
          <Button variant="contained" type="submit">
            Add User
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
