import Http from "./Http";

const http = new Http();

export default class piorService {
  static getPIOR() {
    return http.get<any>({
      endpoint: `/heart/pior/`,
    });
  }
  static postPIOR(args: any) {
    return http.post<any>({
      endpoint: `/heart/pior/`,
      payload: args,
    });
  }
  static patchPIOR(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/pior/${id}/`,
      payload: args,
    });
  }
}
