import Http from "./Http";

const http = new Http();

export default class bodTagMemberService {
  // GET: /bod-tag-member/
  static getMembers() {
    return http.get<any>({
      endpoint: `/bod-tag-member/`,
    });
  }

  // POST: /bod-tag-member/
  static postMember(args: any) {
    return http.post<any>({
      endpoint: `/bod-tag-member/`,
      payload: args,
    });
  }

  // GET: /bod-tag-member/{uuid}/
  static getMemberById(uuid: string) {
    return http.get<any>({
      endpoint: `/bod-tag-member/${uuid}/`,
    });
  }

  // PUT: /bod-tag-member/{uuid}/
  static putMember(uuid: string, args: any) {
    return http.put<any>({
      endpoint: `/bod-tag-member/${uuid}/`,
      payload: args,
    });
  }

  // PATCH: /bod-tag-member/{uuid}/
  static patchMember(uuid: string, args: any) {
    return http.patch<any>({
      endpoint: `/bod-tag-member/${uuid}/`,
      payload: args,
    });
  }

  // DELETE: /bod-tag-member/{uuid}/
  static deleteMember(uuid: string) {
    return http.delete<any>({
      endpoint: `/bod-tag-member/${uuid}/`,
    });
  }
}
