import Http from "./Http";

const http = new Http();

export default class iqfusioncadService {
  static getiqfusioncad() {
    return http.get<any>({
      endpoint: `/heart/iqfusioncad/`,
    });
  }
  static postiqfusioncad(args: any) {
    return http.post<any>({
      endpoint: `/heart/iqfusioncad/`,
      payload: args,
    });
  }
  static patchiqfusioncad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/iqfusioncad/${id}/`,
      payload: args,
    });
  }
}
