import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Auth from '../../../common/services/UserService';

interface FormData {
    name: string;
    email: string;
    phone_number: string;
    NDASigned: boolean;
}

const AddPartner = () => {
    const { control, handleSubmit, } = useForm<FormData>();

    const navigate = useNavigate();
    const onSubmit = (data: FormData) => {
        console.log("data", data);
        Auth.postPartners(data)
            .then((response) => {
                navigate('/admin/partners/');
                if (response.ok) {
                    alert("User Added");
                } else {
                    throw new Error('Network response was not ok');
                }
            })
            .catch(error => {
                console.error('Error adding user:', error);
                // Handle the error here (e.g., display an error message)
            });
    }



    return (
        <div>
            <h1> Partner Form</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Partner Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />
                    )}
                />
                <Controller
                    name="phone_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />
                    )}
                />
                <Controller
                    name="NDASigned"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="NDA Signed"
                        />
                    )}
                />
                <Button variant="contained" type="submit">Save</Button>
            </form>
        </div>
    );
}

export default AddPartner;
