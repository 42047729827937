import React, { useState, useEffect } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import BoDPersonnelForm from "./BoDPersonnelForm";
import bodPersonnel from "../../../common/services/bodPersonel";

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170, align: "left" },
];

const BoDPersonnelView = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  useEffect(() => {
    fetchPersonnel();
  }, []);

  const fetchPersonnel = async () => {
    try {
      const response = await bodPersonnel.getPersonnel();
      setRows(response);
    } catch (error) {
      console.error("Error fetching personnel:", error);
    }
  };

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.uuid === editData.uuid ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.uuid !== rowToDelete.uuid));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  // Function to handle data refresh
  const refreshData = () => {
    fetchPersonnel();
  };

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>BOD Personnel</div>
          <div onClick={() => setOpenModal(true)} className={style.openModel}>
            Add Personnel
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Row</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={editData?.name || ""}
              onChange={handleChange}
            />
            <TextField
              label="Designation"
              name="designation"
              fullWidth
              margin="normal"
              value={editData?.designation || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this row?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="secondary" className={style.modelButton}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={style.modelTitle}>
            BoD Personnel
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BoDPersonnelForm onSuccess={refreshData} onClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default BoDPersonnelView;
