import Http from "./Http";

const http = new Http();

export default class PpoTvService {
  static getPPOTV() {
    return http.get<any>({
      endpoint: `/heart/ppotv/`,
    });
  }
  static postPPOTV(args: any) {
    return http.post<any>({
      endpoint: `/heart/ppotv/`,
      payload: args,
    });
  }
  static patchPPOTV(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/ppotv/${id}/`,
      payload: args,
    });
  }
}
