import Http from "./Http";

const http = new Http();

export default class em7cadService {
  static getem7cad() {
    return http.get<any>({
      endpoint: `/heart/em7cad/`,
    });
  }
  static postem7cad(args: any) {
    return http.post<any>({
      endpoint: `/heart/em7cad/`,
      payload: args,
    });
  }
  static patchem7cad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/em7cad/${id}/`,
      payload: args,
    });
  }
}
