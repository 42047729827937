import React, { useEffect, useState } from "react";
import psseService from "../../../common/services/Psse";
import Preview from "../../../common/components/Dialog/Preview";
import CustomEditor from "../../../common/components/CustomEditor";
import { toast } from "react-toastify";

const PSSE = () => {
  const [psseData, setpsseData] = useState([]);
  const [id, setId] = useState();
  const isPatch = !!psseData && !!id;
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreview(true);
  }
  const handleClosePreview = () => {
    setOpenPreview(false);
  }
  const handleSubmit = (data: any) => {
    const payload = {
      htmltext: data
    }
    if (isPatch) {
      psseService.patchPSSE(payload, id)
        .then((response: any) => {
          toast.success("Saved successfully!");
        })
        .then((error: any) => {
          toast.error('Failed to save!');
        });
    } else {
      psseService.postPSSE(payload)
        .then((response: any) => {
          toast.success("Saved successfully!");
        })
        .then((error: any) => {
          toast.error('Failed to save!');
        });
    }
  };

  useEffect(() => {
    psseService.getPSSE()
      .then((response: any) => {
        const dataString = response[0]?.htmltext;
        setpsseData(JSON.parse(dataString).data);
        setId(response[0]?.id);
      })
      .catch((error: any) => {
        toast.error('Failed to load!');
      });
  }, []);

  return (
    <div style={{ marginTop: "3.5rem" }}>
      <CustomEditor submitHandler={handleSubmit} data={psseData} handleOpenPreview={handleOpenPreview} />
      <Preview title='Packsize Standard Solution Examples' open={openPreview} onClose={handleClosePreview} blocks={psseData} />
    </div>
  );
};

export default PSSE;
