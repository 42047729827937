import Http from "./Http";

const http = new Http();

export default class psseService {
  static getPSSE() {
    return http.get<any>({
      endpoint: `/heart/psse/`,
    });
  }
  static postPSSE(args: any) {
    return http.post<any>({
      endpoint: `/heart/psse/`,
      payload: args,
    });
  }
  static patchPSSE(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/psse/${id}/`,
      payload: args,
    });
  }
}
