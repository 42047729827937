import React, { useState, useEffect } from "react";
import ReusableTable from "../FormComponent/ReusableTable";
import { Column } from "../FormComponent/column";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import AdminHeader from "../../../Layouts/AdminLayout/AdminHeader";
import BoDTagForm from "./BoDTagForm";
import bodTagService from "../../../common/services/bodTagService";

const columns: Column[] = [
  { id: "tag_name", label: "Tag Name", minWidth: 170, align: "left" }
];

const BoDTagView = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const [rowToDelete, setRowToDelete] = useState<any>(null);

  const fetchTags = async () => {
    setLoading(true);
    try {
      const response = await bodTagService.getTags();
      setRows(response);
    } catch (error) {
      console.error("Failed to fetch tags", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleEdit = (row: any) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: any) => {
    setRowToDelete(row);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = () => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === editData.id ? { ...editData } : row))
    );
    setOpenEdit(false);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      setRows((prevRows) => prevRows.filter((r) => r.id !== rowToDelete.id));
      setRowToDelete(null);
    }
    setOpenDelete(false);
  };

  const handleTagAdded = () => {
    fetchTags(); // Refresh data when a new tag is added
    setOpenModal(false); // Close the modal
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <AdminHeader />
      <div>
        <div className={style.modelParent}>
          <div className={style.pageTitle}>BOD TAG</div>
          <div onClick={() => setOpenModal(true)} className={style.openModel}>
            Add Tag
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReusableTable
            columns={columns}
            rows={rows}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </div>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Row</DialogTitle>
          <DialogContent>
            <TextField
              label="Tag Name"
              name="tag_name"
              fullWidth
              margin="normal"
              value={editData?.tag_name || ""}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this row?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} className={style.cancelDelete}>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="secondary"
              className={style.modelButton}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
          <DialogTitle className={style.modelTitle}>
            BoD Tag
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BoDTagForm onTagAdded={handleTagAdded} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default BoDTagView;
