import Http from "./Http";

const http = new Http();

export default class x4cadService {
  static getx4cad() {
    return http.get<any>({
      endpoint: `/heart/x4cad/`,
    });
  }
  static postx4cad(args: any) {
    return http.post<any>({
      endpoint: `/heart/x4cad/`,
      payload: args,
    });
  }
  static patchx4cad(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/x4cad/${id}/`,
      payload: args,
    });
  }
}
