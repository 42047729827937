
// export default Preview;


import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
// import OutputParse from '../../OutputParse';
import style from './style.module.scss'
import { IBlocks } from '../../CustomEditor';
import CustomParser from '../../CustomEditor/CustomParser';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PreviewProps {
  open: boolean;
  onClose: () => void;
  data?: string;
  title?: string;
  blocks?: IBlocks[];
}

const Preview = (props: PreviewProps) => {
  // const [open, setOpen] = React.useState(false);
  const { open, onClose, title, blocks } = props;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={style.previewMainSection}>
          <div className={style.previewBody}>
            {/* {data && <OutputParse data={JSON.parse(data)} />} */}
            {
              blocks && blocks.map((block: IBlocks) => {
                return <CustomParser block={block} />
              })
            }
          </div>
        </div>

      </Dialog >
    </div >
  );
}
export default Preview