import Http from "./Http";

const http = new Http();

export default class PpsrgService {
  static getPPSRG() {
    return http.get<any>({
      endpoint: `/heart/ppsrg/`,
    });
  }
  static postPPSRG(args: any) {
    return http.post<any>({
      endpoint: `/heart/ppsrg/`,
      payload: args,
    });
  }
  static patchPPSRG(args: any, id:number) {
    return http.patch<any>({
      endpoint: `/heart/ppsrg/${id}/`,
      payload: args,
    });
  }
}
